import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "utils/axios";

import { Modal, Button, Stack, Loader, useToaster } from 'rsuite';

import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'
import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import TaskDetails from 'components/tasks/TaskDetails';
import StackItem from "rsuite/esm/Stack/StackItem";
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import ZipplyEditor from 'components/editor/ZipplyEditor';


interface Props {
  open: boolean;
  taskId: any;
  close: () => void;
}

export default function TaskModal({ open, taskId, close }: Props) {
  const toaster = useToaster();
  const [task, setTask] = useState<any>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [walletState, setWalletState] = useState<any>(null);

  useEffect(() => {
    if (open && taskId) {
      refreshTask()
    }
  }, [taskId])

  const refreshTask = () => {
    getRequest(`/tasks/${taskId}/`).then((res) => {
      setTask(res.data);
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />)
    })
  }

  useEffect(() => {
    if (!walletState) {
      getRequest("integrations/stripe/connect/wallets/").then((res) => {
        if (res.status === 200) {
          setWalletState(res.data);
        } else {
          setWalletState({
            can_post_tasks: false,
            can_solve_tasks: false
          })
        }
      });
    }
  }, [walletState]);

  const handleOpen = () => {
    setShowConfirmationDialog(false);
  }

  const handleClose = () => {
    close();
    setTask(null);
    setShowConfirmationDialog(false);
  }

  const handleTake = () => {
    setShowConfirmationDialog(false);
    postRequest(`/solutions/`, { task_id: taskId })
      .then((res) => {
        handleClose();
        toaster.push(<RequestSuccessMessage message={'Nice! You successfully took a task.'} toaster={toaster} />)
      })
      .catch((err) => {
        toaster.push(<RequestErrorMessage error={err} toaster={toaster} />)
      })
  }

  const isPastDue = (dueDate: any) => {
    return new Date(dueDate) < new Date();
  }

  return (
    <Modal size="lg" open={open} onClose={handleClose} onOpen={handleOpen}>
      <Modal.Header>
        <Modal.Title>Task ({task?.current_status})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {task ? <TaskDetails task={task} showDetails={false} showSolutionDetails={false} showNetAmount={true} /> : <Loader size="md" content="Fetching task details..." />}
        <p style={{marginTop: "15px"}}><strong>Task Overview:</strong></p>
        <div style={{ height: "400px" }}>
          <ZipplyEditor key={task?.id + '-description'} content={task?.description} readOnly={true} height={200} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Stack alignItems="flex-start" justifyContent="space-between" spacing={10}>
          <Stack.Item>
            {walletState?.can_solve_tasks === false && (
              <span style={{ fontSize: "14px", color: "red" }}>In order to solve a task, please configure a payout account under <Link onClick={handleClose} to="/wallet">Manage Wallet</Link> first.</span>
            )}
          </Stack.Item>
          <StackItem>
            {task?.current_status === 'POSTED' &&
              <ConfirmationDialog open={showConfirmationDialog} handleClose={() => setShowConfirmationDialog(false)} handleConfirm={handleTake} title="Earnings Acknwoledgement for Solving a Task">
                <div style={{ fontSize: "12px" }}>
                  <p>As a solver, your earnings will be available once a tasker accepts your solution, but they will be classified as deferred earnings during the MVP phase of Zipply.</p>

                  <p>Payments are deferred for 30 days after acceptance and will be automatically paid out on the 28th of each month. A verified payout account through Stripe Connect is required to access your funds.</p>

                  <p>We recognize this may limit some users, and we plan to introduce more payout options in the future.</p>

                  <p>Please confirm your understanding of this payment structure by clicking the <strong>Confirm</strong> button below.</p>
                </div>
              </ConfirmationDialog>
            }
            {(task?.current_status === 'POSTED' && (task?.invite_only || isPastDue(task.due_date))) && <Button appearance="primary" disabled={!walletState?.can_solve_tasks} color="green" onClick={() => setShowConfirmationDialog(true)}>Request Invite</Button>}
            {task?.current_status === 'POSTED' && !task?.invite_only && !isPastDue(task.due_date) && <Button appearance="primary" disabled={!walletState?.can_solve_tasks} color="green" onClick={() => setShowConfirmationDialog(true)}>Solve</Button>}
          </StackItem>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
