import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Row, Col, Header, Stack, Button, InputGroup, Input, IconButton, Divider, Drawer } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search'; // Import search icon
import MenuIcon from '@rsuite/icons/Menu'; // Import menu icon for mobile
import { useDebounce } from 'use-debounce';

import FAQModal from "components/help/FAQModal";
import AnnouncementModal from "components/help/AnnouncementModal";
import TaskIdeasModal from "components/help/TaskIdeasModal";
import HowItWorksModal from "components/help/HowItWorksModal";
import SignInModal from "components/auth/SignInModal";
import SignUpModal from "components/auth/SignUpModal";
import ResetPasswordModal from "components/auth/ResetPasswordModal";

import logo from "assets/images/zipply-logo-text-100x50.png"
import headerSlice from "store/slices/header"

interface Props {
  showSearch: boolean
  signIn?: boolean
  signUp?: boolean
  resetPassword?: boolean
}

const PublicHeader = ({ showSearch, signIn, signUp, resetPassword }: Props) => {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(signIn || false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(signUp || false);
  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);
  const [taskIdeasModalOpen, setTaskIdeasModalOpen] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(signUp || false);
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);

  const dispatch = useDispatch();
  const [delayedSearchQuery, setDelayedSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(delayedSearchQuery, 500);

  useEffect(() => {
    dispatch(headerSlice.actions.setSearchQuery(debouncedQuery));
  }, [debouncedQuery]);

  useEffect(() => {
    setSignInModalOpen(signIn || false);
    if (!signIn) {
      setSignUpModalOpen(signUp || false);
    }
    if (!signIn && !signUp) {
      setResetPasswordModalOpen(resetPassword || false);
    }
  }, [signIn, signUp, resetPassword]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  function handleResetPasswordClose() {
    setResetPasswordModalOpen(false);
    navigate("/");
  }

  function handleSignUpModalClose() {
    setSignUpModalOpen(false);
    navigate("/");
  }

  function handleSignInModalClose() {
    setSignInModalOpen(false);
    navigate("/");
  }

  return (
    <>
      <AnnouncementModal open={announcementModalOpen} close={() => setAnnouncementModalOpen(false)} />

      <Header style={{ backgroundColor: "#fff", padding: "15px 20px", borderBottom: "1px solid #e5e5e5" }}>
        <ResetPasswordModal open={resetPasswordModalOpen} handleClose={handleResetPasswordClose} />

        <Row style={{ display: "flex", alignItems: "center" }}>
          {/* Hamburger Menu for XS screens, aligned to the left */}
          <Col xs={8} sm={8} mdHidden lgHidden xlHidden xxlHidden>
            <Button
              appearance="subtle"
              onClick={toggleDrawer}
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <MenuIcon style={{ fontSize: '24px', color: '#000' }} />
            </Button>
          </Col>

          {/* Logo Section */}
          <Col xs={12} sm={12} md={4} lg={3}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ display: "block" }} />
            </Link>
          </Col>

          {/* Search Bar Section for larger screens */}
          <Col xsHidden smHidden mdHidden lg={9} xl={14} xxl={16}>
            {showSearch &&
              <InputGroup inside style={{ width: "100%" }}>
                <Input value={delayedSearchQuery} onChange={setDelayedSearchQuery} placeholder="Find tasks" style={{ height: "40px" }} />
                <SearchIcon style={{marginTop: "12px", width: "40px"}}/>
              </InputGroup>
            }
          </Col>

          {/* Buttons Section for larger screens */}
          <Col xsHidden smHidden md={20} lg={12}>
            <Stack direction="row" spacing={10} alignItems="center" justifyContent="flex-end">
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setHowItWorksModalOpen(true)}
                >
                  How It Works!
                </Button>
                <HowItWorksModal open={howItWorksModalOpen} close={() => setHowItWorksModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setTaskIdeasModalOpen(true)}
                >
                  Task Ideas
                </Button>
                <TaskIdeasModal open={taskIdeasModalOpen} close={() => setTaskIdeasModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setFaqModalOpen(true)}
                >
                  FAQs
                </Button>
                <FAQModal open={faqModalOpen} close={() => setFaqModalOpen(false)} />
              </Stack.Item>     
              <Stack.Item>
                <Link to="/about">
                  <Button
                    appearance="link"
                    className="linkButton"
                  >
                    About
                  </Button>
                </Link>
              </Stack.Item>            
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setSignInModalOpen(true)}
                >
                  Sign In
                </Button>
                <SignInModal open={signInModalOpen} handleClose={handleSignInModalClose} />
              </Stack.Item>
              <Stack.Item>
                <Link to="/signup">
                  <Button
                    appearance="primary"
                    className="greenButton"
                  >
                    Join
                  </Button>
                </Link>
              </Stack.Item>
            </Stack>
          </Col>

          <Col xs={4} sm={4} mdHidden lgHidden xlHidden xxlHidden>
            <Button
              appearance="primary"
              style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", color: "#fff", backgroundColor: "#4eab0d", fontSize: "16px" }}
              onClick={() => setSignUpModalOpen(true)}
            >
              Join
            </Button>
            <SignUpModal open={signUpModalOpen} handleClose={handleSignUpModalClose} />
          </Col>
        </Row>
        <Row>
          <div style={{textAlign: "center", marginTop: "5px", fontSize: "18px", fontWeight: "800", color: "#4eab0d"}}>
          Post simple tasks for instant help. Solve tasks from anywhere to earn money fast!
          <p onClick={() => setAnnouncementModalOpen(true)} style={{marginBottom: "2px", fontSize: "10px", fontWeight: "600", color: "red", cursor: 'pointer'}}>Zipply is Live! 🎉 We're excited to launch our MVP and can't wait for you to explore it. Click <span style={{color: "#3d8a0a"}}>here</span> to learn more and start posting or solving tasks.</p>
          </div>
        </Row>
      </Header>

      {/* Divider between header and content */}
      <Divider style={{ margin: 0 }} />

      {/* Drawer for mobile menu */}
      <Drawer
        size="xs"
        placement="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Drawer.Header>
          <Drawer.Title>Menu</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Stack direction="column" spacing={10}>
          <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setHowItWorksModalOpen(true)}
                >
                  How It Works!
                </Button>
                <HowItWorksModal open={howItWorksModalOpen} close={() => setHowItWorksModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setTaskIdeasModalOpen(true)}
                >
                  Task Ideas
                </Button>
                <TaskIdeasModal open={taskIdeasModalOpen} close={() => setTaskIdeasModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setFaqModalOpen(true)}
                >
                  FAQs
                </Button>
                <FAQModal open={faqModalOpen} close={() => setFaqModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Link to="/about">
                  <Button
                    appearance="link"
                    className="linkButton"
                    onClick={() => setDrawerOpen(false)}
                  >
                    About
                  </Button>
                </Link>
              </Stack.Item>   
            <Stack.Item>
              <Button
                appearance="link"
                className="linkButton"
                onClick={() => setSignInModalOpen(true)}
              >
                Sign In
              </Button>
              <SignInModal open={signInModalOpen} handleClose={handleSignInModalClose} />
            </Stack.Item>

            <Stack.Item>
              <Button
                appearance="primary"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", color: "#fff", backgroundColor: "#4eab0d", fontSize: "16px" }}
                onClick={() => setSignUpModalOpen(true)}
              >
                Join
              </Button>
              <SignUpModal open={signUpModalOpen} handleClose={handleSignUpModalClose} />
            </Stack.Item>
          </Stack>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default PublicHeader;
