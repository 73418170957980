import { useEffect } from 'react';
import { Modal, Table } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

const { Column, HeaderCell, Cell } = Table;

interface Props {
    open: boolean;
    close: () => void;
}

const TaskIdeasModal = ({ open, close }: Props) => {
    useEffect(() => {
        sendGAEvent("Task Ideas", "View", "Task Ideas Page");
    }, []);

    const handleClose = () => {
        close();
    };

    const taskCategories = [
        {
            category: "Content Creation",
            description: "Generate text-based content such as product descriptions, blog posts, social media captions, scripts, and stories.",
            examples: "Write product descriptions, create catchy blog titles, draft social media posts, and write short stories or poems."
        },
        {
            category: "Research & Information Gathering",
            description: "Perform online research to collect and compile information on various topics, trends, and ideas.",
            examples: "Research influencers, compile trending hashtags, find vacation destinations, and research tools or platforms."
        },
        {
            category: "Design & Creative Work",
            description: "Create visual elements, design logos, graphics, or digital assets, and develop creative visual content.",
            examples: "Design business cards, create social media graphics, make logos, and create custom infographics or flyers."
        },
        {
            category: "Video & Audio Editing",
            description: "Edit and enhance video or audio content for presentations, social media, and other purposes.",
            examples: "Trim video clips, add subtitles, edit music videos, and mix and master audio tracks."
        },
        {
            category: "Personal Assistance",
            description: "Delegate everyday tasks to save time, such as managing calendars, booking reservations, or organizing information.",
            examples: "Organize files, schedule events, create to-do lists, and manage emails."
        },
        {
            category: "Fun & Entertainment",
            description: "Engage in humorous, imaginative, or entertaining challenges and activities.",
            examples: "Invent funny product reviews, come up with silly business ideas, create custom memes, and invent new slang words."
        },
        {
            category: "Data Entry & Document Formatting",
            description: "Format and organize data, documents, or spreadsheets to make information more accessible and professional.",
            examples: "Enter receipt data into spreadsheets, format resumes, and create presentation slides."
        },
        {
            category: "Translation & Language Tasks",
            description: "Translate content between languages or adapt language for specific audiences.",
            examples: "Translate emails, documents, or websites, and write engaging scripts in different languages."
        },
        {
            category: "Marketing & Sales Support",
            description: "Assist with marketing strategies, content creation, and promotion for businesses or individuals.",
            examples: "Write email sequences, design ads, create marketing plans, and develop content calendars."
        },
        {
            category: "Tech & Web Support",
            description: "Create or modify websites, landing pages, or tech-related content.",
            examples: "Develop landing pages, create HTML email templates, and update WordPress plugins."
        },
        {
            category: "Event Planning & Organization",
            description: "Plan and organize events or campaigns, both virtual and in-person, to streamline activities.",
            examples: "Organize a webinar, plan a virtual event, or create content for event promotion."
        },
        {
            category: "Social Media & Online Presence",
            description: "Enhance online presence, engage with audiences, and maintain social media profiles.",
            examples: "Optimize LinkedIn profiles, manage Instagram highlights, and create engaging content for social platforms."
        },
        {
            category: "Illustration & Drawing",
            description: "Use artistic skills to create custom illustrations, sketches, or design assets.",
            examples: "Draw cartoons, illustrate superheroes, and create digital artwork or personalized graphics."
        },
        {
            category: "DIY & Home Organization",
            description: "Delegate creative or mundane tasks related to home organization, crafts, and everyday life.",
            examples: "Create grocery lists, design birthday invitations, and make personalized playlists."
        },
        {
            category: "Advice & Consultations",
            description: "Get advice or opinions on various topics, from work-related challenges to personal matters.",
            examples: "Ask for career advice, get guidance on relationship issues, or receive feedback on creative projects."
        },
        {
            category: "Entertainment & Challenges",
            description: "Have fun with personalized, quirky, or creative challenges that spark joy and curiosity.",
            examples: "Write dramatic stories about daily routines, create fake horoscopes, or invent new holidays."
        },
        {
            category: "Paid Interviews & Surveys",
            description: "Conduct interviews, surveys, or research studies to gather insights or opinions on specific topics.",
            examples: "Conduct market research, gather feedback on products, or interview people for insights."
        },
        {
            category: "Coding Challenges & Projects",
            description: "Create coding challenges, projects, or tasks to test or enhance programming skills.",
            examples: "Solve coding puzzles, create mini projects, and build small applications or scripts."
        },
        {
            category: "Skills Assessment",
            description: "Test or evaluate skills, knowledge, or abilities in various areas to gain insights or feedback.",
            examples: "Take personality quizzes, complete skill assessments, and participate in creative challenges."
        },
        {
            category: "Local News and Updates",
            description: "Capture and share timely information from your community about current events or changes.",
            examples: "Photograph road closures, weather events, or local parades."
        },
        {
            category: "Community Resilience",
            description: "Document positive actions in response to challenges, showcasing community strength and recovery.",
            examples: "Capture photos of volunteers distributing aid or teams cleaning up after a storm."
        },
        {
            category: "Cultural Highlights",
            description: "Share unique traditions, celebrations, or historical landmarks from your area.",
            examples: "Snap a picture of a traditional dance or a historic building lit up for a holiday."
        },
        {
            category: "Travel and Scenery",
            description: "Capture the beauty of different locations, landmarks, and natural wonders for inspiration or documentation.",
            examples: "Take photos of sunsets, unique angles of landmarks, or hidden gems in local areas."
        },
        {
            category: "Cultural and Seasonal Events",
            description: "Document local traditions, festivals, and seasonal celebrations to share cultural experiences.",
            examples: "Capture festival floats, decorated homes during holidays, or vibrant street markets."
        },
        {
            category: "Local Oddities and Curiosities",
            description: "Explore and photograph unique, quirky, or fascinating aspects of a location.",
            examples: "Snap photos of unusual sculptures, weirdly shaped buildings, or interesting street fashion."
        },
        {
            category: "Research and Data Collection",
            description: "Provide real-world insights or data by photographing specific items or locations.",
            examples: "Take pictures of store displays, urban construction sites, or price tags for market research."
        },
        {
            category: "Fun and Games",
            description: "Engage in lighthearted and creative challenges through photography.",
            examples: "Participate in scavenger hunts, capture meme-worthy moments, or take on perspective challenges."
        },
        {
            category: "Educational and Environmental Goals",
            description: "Support learning and awareness by capturing photos of educational or environmental significance.",
            examples: "Photograph clean parks, historical buildings, or local wildlife for study or conservation."
        }
    ];

    return (
        <Modal 
            size="lg" 
            open={open} 
            onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><div style={{textAlign: "center", margin: "5px", fontSize: "20px", fontWeight: "800", color: "#4eab0d"}}>Endless Possibilities to Help You Every Day!</div></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize: "13px"}}>
                <p>
                    Whether you're looking to free up some time, get a fresh perspective, or just add a touch of creativity to your day,
                    there are endless ways to make your life easier. Explore the categories below and get inspired to create tasks that can help simplify your daily routine, or even just make life a little more fun!
                </p>

                <Table height={800} data={taskCategories} wordWrap="break-word">
                    <Column flexGrow={1} align="left">
                        <HeaderCell>Category</HeaderCell>
                        <Cell dataKey="category" style={{fontWeight: "800"}} />
                    </Column>
                    
                    <Column flexGrow={2} align="left">
                        <HeaderCell>Description</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>
                    
                    <Column flexGrow={2} align="left">
                        <HeaderCell>Examples</HeaderCell>
                        <Cell dataKey="examples" />
                    </Column>
                </Table>
            </Modal.Body>
        </Modal>
    );
};

export default TaskIdeasModal;