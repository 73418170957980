import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SideMenu = {
  activeItem: string
};

const initialState: SideMenu = { activeItem: '/' };

const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState,
  reducers: {
    setActiveItem(state: SideMenu, action: PayloadAction<any>) {
      state.activeItem = action.payload;
    },
  },
});

export default sideMenuSlice;
