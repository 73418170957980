import { useState } from 'react';
import { putRequest } from "utils/axios";
import { Modal, Button, Form, FlexboxGrid, useToaster } from 'rsuite';

import TextArea from 'components/elements/Textarea';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';

interface Props {
    open: boolean;
    close: () => void;
    solution: any;
}

const SolutionRejectionReasonModal = ({ open, close, solution } : Props ) => {
    const toaster = useToaster();
    const [reason, setReason] = useState(''); 

    const handleReaonSubmit = () => {
        if (reason === '') {
            toaster.push(<RequestErrorMessage error='Please provide a reason for rejecting the solution.' toaster={toaster} />);
            return;
        }
        putRequest(`/solutions/${solution.id}/reject/`, { reason }).then((res) => {
            toaster.push(<RequestSuccessMessage message={'You successfully rejected the solution.'} toaster={toaster} />)
            handleClose();
        }).catch((error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        })
    };

    const handleClose = () => {
        setReason('');
        close();
    };

    return (
        <Modal size="sm" open={open} onClose={handleClose} backdrop="static">
            <Modal.Header>
                <Modal.Title>Reason for Rejection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{color: 'red', fontSize: "12px"}}>Rejecting a solution indicates that the submitted content is inappropriate for the task, rather than simply not meeting every requirement. This action should be used when the solution fundamentally fails to align with the task’s purpose or contains unsuitable content.</p>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={24}>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel><strong>Leave a reason</strong></Form.ControlLabel>
                                <Form.Control
                                    accepter={TextArea}
                                    name="textarea"
                                    rows={3}
                                    placeholder="Please share a reason for rejecting this solution."
                                    value={reason}
                                    onChange={(value) => setReason(value)}
                                />
                            </Form.Group>
                        </Form>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Body>

            <Modal.Footer>
                <Button appearance="primary" onClick={handleReaonSubmit}>
                    Submit Reason
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SolutionRejectionReasonModal;
