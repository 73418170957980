import React, { useEffect } from 'react';

import { Grid, Row } from 'rsuite';

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';

interface Props {
    signIn?: boolean
    signUp?: boolean
    resetPassword?: boolean
  }
  
export default function PrivacyPolicy({ signIn, signUp, resetPassword }: Props) {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
<div>
    <PublicHeader 
        showSearch={false}
        signIn={signIn} 
        signUp={signUp} 
        resetPassword={resetPassword} 
    />
    <Grid fluid>
        <Row style={{ margin: '20px' }}>
<h1>Privacy Policy</h1>
<p>Last Updated: November 11, 2024</p>

<p>Zipply, operated by Zipply ("we", "us", or "our"), is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you visit our website (https://zipply.io) and use our Zipply web app (collectively, the "Service").  The Zipply website and the Zipply web app are owned by Zipply, which is a data controller of your personal data.</p>

<p>We have adopted this Privacy Policy, which determines how we are processing the information collected by Zipply, which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using the Zipply website and before using the Zipply web app.</p>

<p>We take care of your personal data and undertake to guarantee its confidentiality and security.</p>

<p>1. Information Collection and Use</p>

<p>Personal Information We Collect: When you visit Zipply, we automatically collect certain information about your device, such as your web browser, IP address, time zone, and some installed cookies. We also collect information about the individual web pages or products you view, what websites or search terms referred you to the Site, and how you interact with the Site. This is referred to as "Device Information."</p>

<p>Additionally, we may collect personal data you provide to us during registration, including your name, address, payment information, and more. When you use the Zipply web app, we may collect additional personal data through your interactions with our Service, such as your email address, job title, phone number, profile picture, email signature, user preferences, and any information you provide during job searches or other activities.</p>

<p>Why Do We Process Your Data? Our top priority is customer data security. We may process only minimal user data necessary to maintain the Service. Information collected automatically is used to identify potential cases of abuse and establish statistical information regarding website usage. This statistical information is not otherwise aggregated in such a way that it would identify any particular user of the system.</p>

<p>The information you provide helps us:</p>

<ul>
  <li>Operate, maintain, and improve our Service.</li>
  <li>Provide and deliver products and services you request.</li>
  <li>Send you technical notices, updates, security alerts, and support messages.</li>
  <li>Respond to your comments, questions, and customer service requests.</li>
  <li>Monitor and analyze trends, usage, and activities in connection with our Service.</li>
</ul>

<p>Data Security: Zipply is committed to protecting the security of your personal data, including the files uploaded to our platform. We use appropriate technical and organizational measures to safeguard this information from unauthorized access, disclosure, or misuse.</p>

<p>Data Retention: Your uploaded files will be retained for as long as your account remains active or as necessary to provide you with the services, comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

<p>Google User Data: If you sign in to our Service using your Google account, we will collect and store information associated with your Google account, such as your email address and profile information. This data is used to provide a personalized experience within our Service, streamline account creation, and enhance user interactions.</p>

<p>2. Payment Processing</p>

<p>We use Stripe to process payments. When you provide payment information, it is transmitted directly to Stripe. We do not store your full credit card information on our servers. Stripe collects and processes payment information in accordance with their own privacy policy, which can be viewed at https://stripe.com/privacy. The information Stripe collects may include your credit card number, billing address, and other necessary details to process your payment.</p>

<p>3. Data Sharing and Disclosure</p>

<p>How We Share Your Information: We do not share your personal data with third parties except as described in this Privacy Policy or with your consent. We may share information with:</p>

<ul>
  <li>Analytics providers to help us understand how our Service is used.</li>
  <li>Authorities if required by law or to protect our legal rights.</li>
  <li>Service providers who perform services on our behalf.</li>
  <li>We may share your personal data with third-party service providers, including Stripe, for the purpose of processing payments. Stripe handles your payment information securely and in accordance with its privacy practices.</li>
</ul>

<p>4. User Rights</p>

<p>Your Rights: If you are a European resident, you have the following rights related to your personal data:</p>

<ul>
  <li>The right to be informed.</li>
  <li>The right of access.</li>
  <li>The right to rectification.</li>
  <li>The right to erasure.</li>
  <li>The right to restrict processing.</li>
  <li>The right to data portability.</li>
  <li>The right to object.</li>
  <li>Rights in relation to automated decision-making and profiling.</li>
</ul>

<p>To exercise these rights, please contact us at legal@zipply.io.</p>

<p>5. Data Security</p>

<p>Information Security: We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We keep reasonable administrative, technical, and physical safeguards to protect against unauthorized access, use, modification, and personal data disclosure in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</p>

<p>6. Links to Other Websites</p>

<p>Our website may contain links to other websites that are not owned or controlled by us. We are not responsible for the privacy practices of such other websites. We encourage you to read the privacy statements of each website that may collect personal information.</p>

<p>7. Legal Disclosure</p>

<p>We will disclose any information we collect, use, or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>

<p>8. Limited Use Policy</p>

<p>Zipply's use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.</p>

<p>9. Changes to This Privacy Policy</p>

<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

<p>10. Contact Information</p>

<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to legal@zipply.io.</p>
        </Row>
    </Grid>
    <PublicFooter />
</div>
  );
};
