import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from "react-router-dom";

const Navigate = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("WOOOOOO " + searchParams.toString())
    const queryString = searchParams.toString()
    if (queryString) {
      navigate(`/${params['*']}?${queryString}`)
    } else {
      navigate(`/${params['*']}`)
    }
  })
  return <></>
};

export default Navigate;