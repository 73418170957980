import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { useNavigate } from "react-router";

import { AdminAccountResultList, AdminAccount } from "types";

import {
  Input, InputGroup, Table, Checkbox, Stack, CellProps, Avatar, Loader, Whisper, Popover, Dropdown, IconButton, Grid, Row, Col
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/legacy/More';
import { sortJsonObject } from 'utils/utils';

const { Column, HeaderCell, Cell } = Table;

type Props = {
  accountCounts: { filtered: number, total: number }
  setAccountCounts: ( jobCounts: any  ) => void
}

function AdminAccountList( { accountCounts, setAccountCounts } : Props ) {
  const navigate = useNavigate();
  
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');

  const accountFetcher = useSWR<AdminAccountResultList>(`/admin/accounts/`, fetcher)
  const accountListResponse = accountFetcher.data as AdminAccountResultList

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
  })

  if (!accountListResponse)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching accounts..."/>
      </div>
    );

  var adminAccounts: AdminAccount [] = accountListResponse.results;
  
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === adminAccounts.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < adminAccounts.length) {
    indeterminate = true;
  }

  function handleCheckAll(_value: any, checked: boolean) {
    const keys = checked ? adminAccounts.map(item => item.id) : [];
    setCheckedKeys(keys);
  };

  function handleCheck(value: any, checked: any) {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  };

  function handleSortColumn(sortColumn: any, sortType: any) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const filteredData = () => {
    const filtered = adminAccounts.filter(adminAccount => {
      const business = adminAccount.business
      if (business.name && !business.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
        return false;
      } else {
        return true;
      }
    });

    if (filtered.length != accountCounts.filtered) {
      setAccountCounts( { filtered: filtered.length, total: adminAccounts.length } )
    }
    return sortJsonObject(filtered, sortColumn, sortType)
  };

  return (
    <>
      <Stack justifyContent="flex-start" spacing={10} style={{paddingBottom: '10px'}}>
        <InputGroup inside>
          <Input placeholder="Search" value={searchKeyword} onChange={setSearchKeyword} />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
      </Stack>

      <Table 
        wordWrap="break-word"
        height={windowHeight - 300}
        data={filteredData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        bordered
        rowHeight={45}
        style={{fontSize: '14px'}}   
      >
        <Column width={50} fixed>
          <HeaderCell style={{ padding: 0 }}>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
          </HeaderCell>
          <Cell dataKey="id" style={{ padding: 0 }}>{(rowData: any) => (
             <Checkbox
              value={rowData.id}
              inline
              onChange={handleCheck}
              checked={checkedKeys.some(item => item === rowData.id)}
              style={{height: '10px'}}
              />
          )}
          </Cell>
        </Column>

        <Column minWidth={100} flexGrow={2} sortable>
          <HeaderCell>Name</HeaderCell>
          <NameCell dataKey="business.name" />
        </Column>
        
        <Column minWidth={50} sortable>
          <HeaderCell>Short Name</HeaderCell>
          <Cell dataKey="business.name_short"/>
        </Column>
        
        <Column minWidth={50} sortable>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="business.type"></Cell>
        </Column>
        
        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Industry</HeaderCell>
          <Cell dataKey="business.industry"></Cell>
        </Column>

        <Column minWidth={120} flexGrow={1}>
          <HeaderCell>
            <MoreIcon />
          </HeaderCell>
          <ActionCell />
        </Column>
      </Table>
    </>
  );
};

export default AdminAccountList;

const NameCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate()

  const speaker = (
    <Popover title="Account">
      <Grid style={{width: '400px'}}>
        <Row>
          <Col lg={4}>Address:</Col>
          <Col lg={20}>{rowData.business.address}</Col>
        </Row>        
        <Row>
          <Col lg={4}>Description:</Col>
          <Col lg={20}>{rowData.business.description}</Col>
        </Row>     
      </Grid>   
    </Popover>
  );

  return (
    <Cell {...props}>
      <div  onClick={() => {navigate('/accounts/' + rowData.id)}}>
        <Whisper placement="top" speaker={speaker}>
          <a>{rowData.full_name}</a>
        </Whisper>
      </div>
    </Cell>
  );
};  

const ActionCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate();

  function handleSelect(eventKey: any) {
    switch (eventKey) {
      case 'edit-account':
        navigate('/admin/accounts/' + rowData.id);
        break;
      case 'edit-business':
        navigate('/admin/accounts/' + rowData.id + '/businesses/' + rowData.business.id);
        break;
      }
  };
    
  return (
    <Cell {...props} className="link-group">
      <div style={{height: '10px'}}>
      <Whisper placement="autoVerticalEnd" trigger="click" speaker={
          <Popover full>
            <Dropdown.Menu onSelect={handleSelect}>
              <Dropdown.Item eventKey='edit-account'>Edit Account</Dropdown.Item>
              <Dropdown.Item eventKey='edit-business'>Edit Business</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
      }>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
      </div>
    </Cell>
  );
};