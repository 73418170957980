import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "rsuite/esm/internals/utils/date";

type Header = {
  message: string
  searchQuery: string
};

const initialState: Header = { message: '', searchQuery: '' };

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setActiveItem(state: Header, action: PayloadAction<any>) {
      state.message = action.payload;
    },
    setSearchQuery(state: Header, action: PayloadAction<any>) {
      state.searchQuery = action.payload;
    },
  }
});

export default headerSlice;
