import { useEffect } from 'react';

import { Modal } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

interface Props {
    open: boolean;
    close: () => void;
}

const HowItWorksModal = ({ open, close }: Props) => {
    useEffect(() => {
        sendGAEvent("How It Works", "View", "How It Works Page");
    }, []);

    const handleClose = () => {
        close();
    };

    const textStyles = {
        paddingLeft: "23px"
    };

    return (
        <Modal 
            size="lg" 
            open={open} 
            onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><div style={{textAlign: "center", margin: "5px", fontSize: "20px", fontWeight: "800", color: "#4eab0d"}}>Welcome to Zipply, where creativity meets convenience!</div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{fontSize: "15px"}}>
                    <p>Whether you have a task that needs quick attention or you're ready to solve one, here’s how it all works:</p>
                    
                    <h5>1. Post a Task in Seconds</h5>
                    <p style={textStyles}>Got something that needs doing? Posting a task on Zipply is as easy as writing a quick description and setting a reward. Whether it’s fixing an image, reviewing a document, or anything in between, you’re just a few clicks away from getting the job done.</p>
                    
                    <h5>2. Watch the Solutions Roll In</h5>
                    <p style={textStyles}>Once your task is live, Zipply’s community of solvers jumps into action! Multiple solutions will come your way, each one bringing fresh creativity to the table. It’s like having a team of experts at your fingertips.</p>
                    
                    <h5>3. Pick Your Favorite</h5>
                    <p style={textStyles}>Browse through the submissions and choose the one that fits your needs best. With Zipply, you’re in control. Once you’ve picked a solution, the solver gets rewarded, and your task is complete!</p>
                    
                    <h5>4. Fast, Simple, and Fun!</h5>
                    <p style={textStyles}>Zipply isn’t just about getting things done—it’s about making the process fun and hassle-free. From creative tasks to simple fixes, it’s the go-to place for quick solutions with a dash of creativity.</p>
                    
                    <strong>Join Zipply today and see how easy it is to post, solve, and succeed!</strong>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HowItWorksModal;
