
import React, { useState, useEffect } from "react";
import { List } from 'rsuite';

import { getRequest } from "utils/axios";

const AdminSystemContent = () => {
  const [backendBuildVersion, setBackendBuildVersion] = useState('Unspecified')

  function getFrontendBuildVersion() {
    const buildVersion = document.getElementById('right-one-app-build-version') as HTMLMetaElement
    const buildTime = document.getElementById('right-one-app-build-time') as HTMLMetaElement
    return [buildVersion.content, 'build: ' + new Date(buildTime.content).toLocaleString()].join(', ')
  }

  useEffect(() => {
    const configurationPromise = getRequest('/system/info/')
    configurationPromise.then((response: any) => {
        setBackendBuildVersion([response.data.version, 'build: ' + new Date(response.data.build_time).toLocaleString()].join(', '))
    })   
  })

  return (<>
      <h5>Release Updates</h5>
      <p>Last frontend release: {getFrontendBuildVersion()}</p>
      <p>Last backend release: {backendBuildVersion}</p>
      <p>Upcoming work: <a href='ADD_HERE' target='_blank'>Trello Board</a></p>
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>08/18/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Initial release</List.Item>
          </List>
        </List.Item>
      </List>
      </>);
};

export default AdminSystemContent;
