import { Grid, Row, Tag } from 'rsuite';

import FilePreview from "components/files/FilePreview";
import ZipplyEditor from 'components/editor/ZipplyEditor';


interface Props {
  solution: any
  showSolver: boolean
}

export default function SolutionDetails({ solution, showSolver }: Props) {
    function getStatusTag(status: string) {
      let color: 'blue' | 'red' | 'green' | 'cyan' | 'orange' | 'violet' | 'yellow' | undefined;
      if (status === 'REJECTED') {
        color = 'red'
      } else if (status === 'ACCEPTED') {
        color = 'green'
      } else {
        color = 'yellow'
      }
      return <Tag color={color}>{status}</Tag>
    }

    function renderUserHandle(user: any) {
      return <a href={`/@${user.username}`} target="_blank" rel="noreferrer">{user.display_name}</a>
    }

    return (
      <Grid fluid>        
        <Row>
            {showSolver && (
              <p><strong>Solver: </strong>{renderUserHandle(solution.solver)} {getStatusTag(solution.current_status)}</p>
            )}
            <div>
              <strong>Attached Files: </strong>
              <div style={{fontSize: "12px"}}>
                {solution.attached_files && solution.attached_files.length > 0 ? (
                  <ul>
                    {solution.attached_files.map((file: any, index: number) => (
                      <li><FilePreview file={file}/></li>
                    ))
                    }
                  </ul>) : (
                  <span>No files attached</span>
                )}
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <strong>Description: </strong>
              <ZipplyEditor key="solution" content={solution.description} readOnly={true} height={350} />
            </div>
        </Row>
      </Grid>
    );
  }
