import { Link } from "react-router-dom";

import { Footer, Stack, Divider, Button } from 'rsuite';

const PublicFooter = () => {
  return (<>
      <Divider />
      <Footer style={{ backgroundColor: "#fff", padding: "15px 20px", borderBottom: "1px solid #e5e5e5" }}>
          <Stack justifyContent="center" spacing="5px" alignItems="flex-start">
            <Stack.Item>
            </Stack.Item>
            <Stack.Item>
              © 2024 Zipply. All Rights Reserved
            </Stack.Item>
            <Stack.Item>
              <Button
                style={{ marginTop: "-6px" }}
                appearance="link"
                className="linkButton"
              >
                  <Link to="/about">About</Link>
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                style={{ marginTop: "-6px" }}
                appearance="link"
                className="linkButton"
              >
                  <Link to="/terms-of-service">Terms of Service</Link>
              </Button>
            </Stack.Item>
            <Stack.Item>
            <Button
                style={{ marginTop: "-6px" }}
                appearance="link"
                className="linkButton"
              >
                  <Link to="/privacy-policy">Privacy Policy</Link>
              </Button>              
            </Stack.Item>
          </Stack>
      </Footer>
</>      
  );
};

export default PublicFooter;
