import React, { useRef, useState } from 'react';
import { Form, useToaster, Schema, Stack, Button, SelectPicker } from 'rsuite';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { postRequest } from 'utils/axios';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import Textarea from 'components/elements/Textarea';

const reasonOptions = [
  { label: "Configuration", value: "Configuration" },
  { label: "Technical Issue", value: "Technical Issue" },
  { label: "Billing Issue", value: "Billing Issue" },
  { label: "General Inquiry", value: "General Inquiry" }
]

const SupportContent = () => {
  const toaster = useToaster();
  const formRef: any = useRef();
  const user = useSelector((state: RootState) => state.auth.user);

  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    subject: '',
    reason: '',
    message: '',
  });

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    subject: StringType().isRequired('This field is required'),
    reason: StringType().isRequired('This field is required'),
    message: StringType().isRequired('This field is required'),
  });

  const handleSubmit = () => {
    if (formRef.current.check()) {
      const supportRequest = {
        subject: formValue.subject,
        reason: formValue.reason,
        message: formValue.message,
      };

      postRequest('/support/messages/', supportRequest)
        .then((response) => {
          toaster.push(<RequestSuccessMessage message="Thank you for contacting us. We'll get back to you shortly." toaster={toaster} />);
          setFormValue(
            {
              subject: '',
              reason: '',
              message: '',
            }
          )
        })
        .catch((error) => {
          toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        });
    }
  };

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      subject: record.subject,
      reason: record.reason,
      message: record.message
    })
  }    

  return (
    <div style={{ display: 'flex' }}>
      <Form
        style={{ width: '60%' }}
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        fluid
      >

        <h5>Contact Support Form</h5>

        <Form.Group controlId="subject">
          <Form.ControlLabel>Subject<sup>*</sup></Form.ControlLabel>
          <Form.Control 
            name="subject" 
            placeholder="Please provide a brief overview of your inquiry"/>
        </Form.Group>

        <Form.Group controlId="reason">
          <Form.ControlLabel>Reason<sup>*</sup></Form.ControlLabel>
          <Form.Control
                        name="reason" 
                        accepter={SelectPicker} 
                        data={reasonOptions}
                        searchable={false}
                        style={{ width: "200px" }}
                        size="md"
                        virtualized
                        placeholder="Select a reason" />
        </Form.Group>

        <Form.Group controlId="message">
          <Form.ControlLabel>Message<sup>*</sup></Form.ControlLabel>
          <Form.Control 
            name="message" 
            rows={10} 
            accepter={Textarea} 
            placeholder="Please provide a detailed description of your inquiry" />
        </Form.Group>

        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='center'>
          <Button style={{width: "100px"}} appearance="primary" type='submit'>Submit</Button>
        </Stack>
      </Form>
      
      <div style={{ width: '40%', marginLeft: '20px', fontSize: "12px" }}>
        <h5>How to Contact Us</h5>
        <p>Before reaching out to us directly through this form, we encourage you to connect with our <a href="https://discord.gg/kTWMRG6P" target="_blank">Discord community</a>, where you can often find quick answers and support from both our team and fellow users.</p>
        <p>We're here to ensure you get the most out of your Zipply experience! Our support team is ready to assist you with any questions or issues you might encounter. Here’s a quick guide on how you can utilize our support package effectively:</p>
        <ul>
          <li><strong>Configuration:</strong> If you need help setting up or configuring any aspect of Zipply, our team is here to guide you through the process.</li>
          <li><strong>Technical Issue:</strong> Experiencing a technical glitch? Let us know! Our support specialists are skilled in troubleshooting and resolving any technical problems you might face.</li>
          <li><strong>Billing Issue:</strong> For any questions or concerns related to billing, our dedicated billing support team is available to assist.</li>
          <li><strong>General Inquiry:</strong> Have a question that doesn’t quite fit into the categories above? No problem! Our support team is happy to address any general inquiries you may have about Zipply.</li>
        </ul>
        <p>Contacting our support team is easy. Simply choose the category that best describes your issue and reach out to us. We’re committed to providing prompt and helpful responses to ensure you get the assistance you need.</p>
        <p>We’re excited to support you on your Zipply journey and look forward to helping you achieve your goals!</p>
      </div>
    </div>
  );
};

export default SupportContent;
