import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { getRequest } from "utils/axios";

import useSWR from 'swr';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { RootState } from "store";
import { useDebounce } from 'use-debounce';

import { Dropdown, Whisper, Avatar, IconButton, WhisperInstance, Popover, Stack, Button } from 'rsuite';
import { Row, Col, Header, InputGroup, Input, Divider, Drawer } from 'rsuite';

import SearchIcon from '@rsuite/icons/Search'; // Import search icon
import MenuIcon from '@rsuite/icons/Menu'; // Import menu icon for mobile

import ReferralModal from "components/referral/ReferralModal";
import FAQModal from "components/help/FAQModal";
import TaskIdeasModal from "components/help/TaskIdeasModal";
import HowItWorksModal from "components/help/HowItWorksModal";
import CreateTaskModal from "components/tasks/CreateTaskModal";
import headerSlice from "store/slices/header"

import logo from "assets/images/zipply-logo-text-100x50.png"

import UserHandleInputModal from "components/auth/UserHandleInputModal"


type Props = {
  isHeaderRefresh: boolean
  acknowledgeHeaderRefresh: () => void
  refreshBody: () => void
  startTour: () => void
};

const ProtectedHeader = ({ refreshBody, isHeaderRefresh, acknowledgeHeaderRefresh, startTour }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const trigger = useRef<WhisperInstance>(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [continuousUpdateEnabled, setContinuousUpdateEnabled] = useState<any>(null);
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);
  const [taskIdeasModalOpen, setTaskIdeasModalOpen] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [referralModalOpen, setReferralModalOpen] = useState(false);

  const [delayedSearchQuery, setDelayedSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(delayedSearchQuery, 500);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFinalUserOnBoardingStepDialogVisible, setIsFinalUserOnBoardingStepDialogVisible] = useState(false);
  const [profile, setProfile] = useState<any>();
  const { data: user, trigger: refreshUser } = useSWRMutation<any>(`/users/0/me/`, fetcher, {
      onSuccess: (data) => {
          if (data) {
              setProfile(data);
              if (data.country_iso && data.username && data.display_name) {
                  setIsFinalUserOnBoardingStepDialogVisible(false);
              } else {
                  setIsFinalUserOnBoardingStepDialogVisible(true);
              }
          }
      }
  });

  useEffect(() => {
    refreshUser()
    acknowledgeHeaderRefresh()
  }, [isHeaderRefresh]);

  useEffect(() => {
    dispatch(headerSlice.actions.setSearchQuery(debouncedQuery));
  }, [debouncedQuery]);

  const login_user = useSelector((state: RootState) => state.auth.user);
  const { data: userStats } = useSWR<any>(`/users/${login_user?.id}/stats/`, fetcher)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (user?.image) {
      setProfileImage(user.image);
    }

    // Call user and configuration updates every 60 seconds (60000 milliseconds)
    if (!continuousUpdateEnabled && !initialized) {
      setInitialized(true);
      console.log('Setting interval to update user and configuration...');
      setContinuousUpdateEnabled(setInterval(updateUserAndConfiguration, 10 * 60000))
    }
  }, [user]);

  function updateUserAndConfiguration(): void {
    if (login_user && login_user.id) {
      getRequest(`/users/${login_user?.id}/`).then((res) => {
        dispatch(authSlice.actions.setUser(res.data));
      }).catch((error) => {
        if (error.response.status === 401) {
          console.log('User is not authenticated. Clearing interval...');
          clearInterval(continuousUpdateEnabled);
        }
      })
      getRequest('/system/configurations/ui/').then((response: any) => {
        dispatch(configurationSlice.actions.setConfiguration(response.data));
      })
    }
  }

  function handleSelect(eventKey: any, onClose: () => void) {
    switch (eventKey) {
      case 'createTask':
        onClose();
        setCreateTaskModalOpen(true);
        break;
      case 'profile':
        onClose();
        navigate('/profile');
        break;
      case 'wallet':
        onClose();
        navigate('/wallet');
        break;
      case 'support':
        onClose();
        navigate('/support');
        break;
      case 'referral':
        onClose();
        setReferralModalOpen(true);
        break;
      case 'about':
        onClose();
        navigate('/about');
        break;
      case 'discord':
        onClose();
        break;
      case 'signout':
        dispatch(authSlice.actions.setLogout());
        navigate('/home');
        break;
    }
  };

  function handleTaskModalClose() {
    setCreateTaskModalOpen(false);
    refreshBody();
  }

  function handlePostTask() {
    refreshUser();
    setCreateTaskModalOpen(true);
  } 

  function handleFinalUserOnBoardingStepDialogClose() {
    setIsFinalUserOnBoardingStepDialogVisible(false);
    refreshUser()
  }

  const renderAdminSpeaker = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={(eventKey: any) => handleSelect(eventKey, onClose)}>
          <Dropdown.Item panel style={{ padding: 10, width: 270 }}>
            <p>Signed in as: <Link to={`/@${user?.username}`} >{user?.username}</Link></p>

            {user?.last_signin_time ?
              (<p>Last sign in:<strong> {new Date(user?.last_signin_time).toLocaleString()}</strong></p>)
              :
              (<p>Never signed in before</p>)
            }
          </Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey='profile'>Manage Profile</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey='wallet'>Manage Wallet</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey='discord'><a href="https://discord.gg/kTWMRG6P" target="_blank">Join our Discord Community!</a></Dropdown.Item>
          <Dropdown.Item eventKey='referral'>Refer a Friend</Dropdown.Item>
          <Dropdown.Item eventKey='support'>Contact Us</Dropdown.Item>
          <Dropdown.Item eventKey='about'>About</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey='signout'>Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <>
      {isFinalUserOnBoardingStepDialogVisible && <UserHandleInputModal profile={profile} open={isFinalUserOnBoardingStepDialogVisible} handleClose={handleFinalUserOnBoardingStepDialogClose} startTour={startTour}/>}

      <Header style={{ backgroundColor: "#fff", padding: "15px 20px"}}>
        <ReferralModal open={referralModalOpen} close={() => setReferralModalOpen(false)} />
        <Row style={{ display: "flex", alignItems: "center"}}>
          {/* Hamburger Menu for XS screens, aligned to the left */}
          <Col xs={4} sm={4} mdHidden lgHidden xlHidden xxlHidden>
            <Button
              appearance="subtle"
              onClick={toggleDrawer}
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <MenuIcon style={{ fontSize: '24px', color: '#000' }} />
            </Button>
          </Col>

          {/* Logo Section */}
          <Col xs={8} sm={8} md={4} lg={3}>
            <Link className="welcome" to="/">
              <img src={logo} alt="Logo" style={{ display: "block" }} />
            </Link>
          </Col>

          {/* Earnings */}
          <Col xs={12} sm={12} md={11} lg={3}>
            <Link to="/wallet" className="totals" style={{ textDecoration: "none", color: "#263c98",  fontSize: "10px", fontWeight: "600" }}>
                <Row>
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">
                    <Stack.Item basis={70}>Total Paid:</Stack.Item>
                    <Stack.Item>${userStats?.total_paid}</Stack.Item>
                  </Stack>
                </Row>
                <Row>
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">                  
                    <Stack.Item basis={70}>Total Earned:</Stack.Item>
                    <Stack.Item>${userStats?.total_earned}</Stack.Item>
                  </Stack>
                </Row>
            </Link>
          </Col>

          {/* Search Bar Section for larger screens */}
          <Col xsHidden smHidden mdHidden lg={8} xl={14} xxl={16}>
              <InputGroup className="find-tasks" inside style={{ width: "100%" }}>
                <Input value={delayedSearchQuery} onChange={setDelayedSearchQuery} placeholder="Find tasks" style={{ height: "40px" }} />
                <SearchIcon style={{marginTop: "12px", width: "40px"}}/>
              </InputGroup>
          </Col>

          {/* Buttons Section for larger screens */}
          <Col xsHidden smHidden md={10} lg={12}>
            <Stack direction="row" spacing={0} alignItems="center" justifyContent="flex-end">
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setHowItWorksModalOpen(true)}
                >
                  How It Works!
                </Button>
                <HowItWorksModal open={howItWorksModalOpen} close={() => setHowItWorksModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setTaskIdeasModalOpen(true)}
                >
                  Task Ideas
                </Button>
                <TaskIdeasModal open={taskIdeasModalOpen} close={() => setTaskIdeasModalOpen(false)} />
              </Stack.Item>
              <Stack.Item>
                <Button
                  appearance="link"
                  className="linkButton"
                  onClick={() => setFaqModalOpen(true)}
                >
                  FAQs
                </Button>
                <FAQModal open={faqModalOpen} close={() => setFaqModalOpen(false)} />
              </Stack.Item>    
              <Stack.Item>
                <Button
                  appearance="primary"
                  className="greenButton post-task"
                  onClick={handlePostTask}
                >
                  Post Task
                </Button>
                <CreateTaskModal open={createTaskModalOpen} close={handleTaskModalClose} />
              </Stack.Item>
              <Stack.Item>
                <Whisper placement="bottomEnd" trigger="click" ref={trigger} speaker={renderAdminSpeaker}>
                  <IconButton
                    appearance='link'
                    icon={
                      <Avatar
                        className="avatar" 
                        size="md"
                        circle
                        src={profileImage}
                        style={{ marginLeft: 20 }}
                      />
                    }
                  />
                </Whisper>
              </Stack.Item>
            </Stack>
          </Col>
        </Row>
        <Row>
          <div style={{textAlign: "center", marginBottom: "2px", fontSize: "10px", fontWeight: "600", color: "red"}}>
          Zipply is currently in its MVP phase. We're working hard to improve the platform and appreciate your patience as we iron out issues. Thank you for being part of our journey!
          </div>
        </Row>
      </Header>

      {/* Divider between header and content */}
      <Divider style={{ margin: 0 }} />

      {/* Drawer for mobile menu */}
      <Drawer
        size="xs"
        placement="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Drawer.Header>
          <Drawer.Title>Menu</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Stack direction="column" spacing={10}>
            <Stack.Item alignSelf="center" style={{ display: 'flex', alignItems: 'center' }}>
              <Dropdown.Menu onSelect={(eventKey: any) => handleSelect(eventKey, () => setDrawerOpen(false))}>
              <Dropdown.Item eventKey='createTask'>Post Task</Dropdown.Item>
                <Dropdown.Item panel style={{ padding: 10, width: 270 }}>
                  <p>Signed in as: <Link to={`/@${user?.username}`} >{user?.username}</Link></p>

                  {user?.last_signin_time ?
                    (<p>Last sign in:<strong> {new Date(user?.last_signin_time).toLocaleString()}</strong></p>)
                    :
                    (<p>Never signed in before</p>)
                  }
                </Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey='profile'>Manage Profile</Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey='wallet'>Manage Wallet</Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey='discord'><a href="https://discord.gg/kTWMRG6P" target="_blank">Join our Discord Community!</a></Dropdown.Item>
                <Dropdown.Item eventKey='referral'>Refer a Friend</Dropdown.Item>
                <Dropdown.Item eventKey='support'>Contact Us</Dropdown.Item>
                <Dropdown.Item eventKey='about'>About</Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey='signout'>Sign Out</Dropdown.Item>
              </Dropdown.Menu>
            </Stack.Item>
          </Stack>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default ProtectedHeader;