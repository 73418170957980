import { useState } from 'react';
import { putRequest } from "utils/axios";

import { Modal, Button, Rate, Checkbox, Form, FlexboxGrid, useToaster} from 'rsuite';

import TextArea from 'components/elements/Textarea';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';

interface Props {
    open: boolean;
    close: () => void;
    task: any;
}

const TaskerFeedbackModal = ({ open, close, task } : Props ) => {
    const toaster = useToaster();

    const [positiveFeedback, setPositiveFeedback] = useState(true); 
    const [starRating, setStarRating] = useState(5); 
    const [skipRating, setSkipRating] = useState(false); 
    const [comment, setComment] = useState('');

    const handleFeedbackSubmit = () => {
        const feedback = {
            positive: positiveFeedback,
            rating: skipRating ? null : starRating,
            comment
        }

        putRequest(`/tasks/${task.id}/feedback/`, feedback).then((res) => {
            toaster.push(<RequestSuccessMessage message={'Thank you for your feedback!'} toaster={toaster} />)
            close();
        }).catch((error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        })        
    };

    return (
        <Modal size="sm" open={open} backdrop="static">
            <Modal.Header>
                <Modal.Title>Please provide feedback for {task?.tasker.display_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={24}>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel><strong>Are you satisfied with this task experience?</strong></Form.ControlLabel>
                                <Checkbox
                                    checked={positiveFeedback}
                                    onChange={(_, checked) => setPositiveFeedback(checked)}
                                >
                                    Yes, I am satisfied.
                                </Checkbox>
                            </Form.Group>

                            {/* Optional Star Rating */}
                            <Form.Group>
                                <Form.ControlLabel><strong>Leave a star rating (optional)</strong></Form.ControlLabel>
                                <div style={{fontSize: '12px', marginBottom: '10px'}}>
                                <p>When choosing your star rating, consider the following:</p>
                                <ul>
                                    <li><strong>Clarity of Instructions:</strong> Were the task details clear and easy to understand?</li>
                                    <li><strong>Responsiveness:</strong> How responsive was the tasker when you needed clarification or had questions?</li>
                                    <li><strong>Timeliness:</strong> Did the tasker approve your solution and process payment in a timely manner?</li>
                                    <li><strong>Communication:</strong> Did the tasker provide constructive feedback and communicate well throughout the task?</li>
                                </ul>
                                </div>
                                <Checkbox
                                    checked={skipRating}
                                    onChange={(_, checked) => setSkipRating(checked)}
                                >
                                    Skip rating
                                </Checkbox>&nbsp;&nbsp;&nbsp; 
                                <Rate
                                    size="sm"
                                    allowHalf
                                    defaultValue={starRating}
                                    disabled={skipRating}
                                    onChange={(value) => setStarRating(value)}
                                />
                            </Form.Group>

                            {/* Optional Comment */}
                            <Form.Group>
                                <Form.ControlLabel><strong>Leave a comment (optional)</strong></Form.ControlLabel>
                                <Form.Control
                                    accepter={TextArea}
                                    name="textarea"
                                    rows={3}
                                    placeholder="Please share any additional comments or suggestions for the tasker. Note: Your comments will be visible on the tasker's public profile."
                                    value={comment}
                                    onChange={(value) => setComment(value)}
                                />
                            </Form.Group>
                        </Form>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Body>

            <Modal.Footer>
                <Button appearance="primary" onClick={handleFeedbackSubmit}>
                    Submit Feedback
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskerFeedbackModal;
