import React, { useState, useEffect } from 'react';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Link } from "react-router-dom";
import { Grid, Row, Panel, Loader, List, Stack, Tag, Whisper, Badge, Tooltip } from 'rsuite';

import { FaExclamationCircle } from 'react-icons/fa'

import { useSelector } from "react-redux";
import {RootState} from "store";

import SolvedTaskModal from 'components/tasks/SolvedTaskModal';
import CanceledTaskModal from 'components/tasks/CanceledTaskModal';
import TaskerSolutionsModal from 'components/solutions/TaskerSolutionsModal';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars, mapTaskStatus } from 'utils/utils';

type Props = {
  isBodyRefresh: boolean
  acknowledgeBodyRefresh: () => void
};

const MyTasks = ({ isBodyRefresh, acknowledgeBodyRefresh }: Props) => {
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
  const [taskerSolutionsModalOpen, setTaskerSolutionsModalOpen] = useState(false);
  const [solvedTaskModalOpen, setSolvedTaskModalOpen] = useState(false);
  const [canceledTaskModalOpen, setCanceledTaskModalOpen] = useState(false);

  const header: any = useSelector((state: RootState) => state.header);
  const { data: activeTasks, trigger: refreshActiveTasks } = useSWRMutation<any>(`/tasks/mine/?q=${header.searchQuery}`, fetcher);

  useEffect(() => {
    refreshActiveTasks()
    acknowledgeBodyRefresh()
  }, [refreshActiveTasks, isBodyRefresh, header.searchQuery]);

  function handleTaskerSolutions(taskId: any) {
    setSelectedTaskId(taskId)
    setTaskerSolutionsModalOpen(true)
  }

  function handleTaskerSolutionClose() {
    setTaskerSolutionsModalOpen(false)
    refreshActiveTasks()
  }

  function handleSolvedTask(taskId: any) {
    setSelectedTaskId(taskId)
    setSolvedTaskModalOpen(true)
  }

  function handleCanceledTask(taskId: any) {
    setSelectedTaskId(taskId)
    setCanceledTaskModalOpen(true)
    refreshActiveTasks()
  }

  function handleCanceledTaskClose() {
    setCanceledTaskModalOpen(false)
  }

  function handleSolvedTaskClose() {
    setSolvedTaskModalOpen(false)
    refreshActiveTasks()
  }

  const TaskListItem = ({ task, onClick }: { task: any; onClick: () => void }) => {
    const { title, current_status, posted_date, reward_in_cents, due_date } = task;

    const displayStatus: any = mapTaskStatus(current_status)
  
    return (
      <List.Item onClick={onClick} style={{ cursor: 'pointer' }}>
        <Row><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item style={{fontSize: "14px"}}>{new Date(posted_date).toLocaleDateString()}</Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item>
                  {task.can_provide_feedback ? 
                    <Whisper onClick={onClick} placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Please provide timely feedback to solver!</Tooltip>}>
                      <Badge content={<FaExclamationCircle style={{ color: 'white', fontSize: '10px', marginTop: "-2px" }}/>}>
                        <Tag color={displayStatus.color} style={{width: "100px", textAlign: "center"}}>{displayStatus.text}</Tag>
                      </Badge>
                    </Whisper>
                    :
                    <Tag onClick={onClick} color={displayStatus.color} style={{width: "100px", textAlign: "center"}}>{displayStatus.text}</Tag>
                  }
                </Stack.Item>
                <Stack.Item style={{width: "80px"}}><small><TimeRemaining endDate={new Date(due_date)}/></small></Stack.Item>
                <Stack.Item><Tag style={{width: "40px", textAlign: "right"}} color="blue">{displayCentAmountInDollars(reward_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
      </List.Item>
    );
  };  

  return (
    <div>
      <Grid fluid>
        <Row style={{ margin: '20px' }}>
          <Panel header={<Link to="/my-tasks" className="nonStyledLink">My Tasks</Link>}>
            {!activeTasks && <Loader size="md" content="Fetching tasks..." />}
            {activeTasks && activeTasks.count === 0 && <p>No tasks available</p>}
              {activeTasks && activeTasks.count > 0 && <>
                <ul>
                  {activeTasks.results.map((task: any, index: number) => {
                      if (task.current_status === 'POSTED')
                        return <TaskListItem task={task} onClick={() => handleTaskerSolutions(task.id)}/>
                      else if (task.current_status === 'CANCELED')
                        return <TaskListItem task={task} onClick={() => handleCanceledTask(task.id)}/>
                      else 
                        return <TaskListItem task={task} onClick={() => handleSolvedTask(task.id)}/>
                      }
                  )}
                </ul>
                <TaskerSolutionsModal taskId={selectedTaskId} open={taskerSolutionsModalOpen} close={handleTaskerSolutionClose} />
                <SolvedTaskModal taskId={selectedTaskId} open={solvedTaskModalOpen} close={handleSolvedTaskClose} />
                <CanceledTaskModal taskId={selectedTaskId} open={canceledTaskModalOpen} close={handleCanceledTaskClose} />
              </>}
              {!activeTasks && <Loader size="md" content="Fetching tasks..."/>}            
          </Panel>
        </Row>
      </Grid>
    </div>
  );
};

export default MyTasks;
