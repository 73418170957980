
import { Divider, Stack, Button, List } from 'rsuite';
import Textarea from 'components/elements/Textarea';

const AdminSystemContent = () => {
  return (<>
      <h5>Control Board</h5>
      <Stack spacing={10} direction='row' alignItems='flex-end' justify-content='flex-end'>
        <Stack.Item><Button appearance="primary" onClick={() => alert('Update likely to move score')}>Update LTM Score</Button></Stack.Item>
      </Stack>   
      </>);
};

export default AdminSystemContent;
