import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { getRequest } from "utils/axios";
import { useNavigate, useLocation } from "react-router-dom";

import Image from 'react-bootstrap/Image';
import { Grid, useToaster, Loader } from 'rsuite';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import { sendGAUserSignIn } from 'components/tracker/GoogleAnalyticsTracker';

const logo = "https://via.placeholder.com/300x50.png?text=Logo"

// Example redirect URL:
// http://localhost:3000/redirect?state=sQuanKOWNrtf5A2qhtrc83BlPgM6GP&code=4/0ATx3LY6vVWieIrNypW2AnpbODoSfxxLKlzXNoauyRMuEAbd7Xrdpy6ci51H_WAGxX1LcsA&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/gmail.send%20openid&authuser=0&hd=zipply.io&prompt=consent
export default function GoogleSignIn() {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const googleCallbackUrl = process.env.REACT_APP_API_URL + '/auth/social/google/callbacks/login/'

  useEffect(() => {
    const redirectParameters: any = location.search
    if (redirectParameters) {
      getRequest(googleCallbackUrl + redirectParameters).then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setUser(res.data.user));
        const configurationPromise = getRequest('/system/configurations/ui/')
        configurationPromise.then((response) => {
          dispatch(configurationSlice.actions.setConfiguration(response.data));
        })   
        sendGAUserSignIn(res.data, "Google")
        navigate("/", {
          state: {
            userId: res.data.id
          }
        });
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
        navigate("/signin")
      })       
    }      
  })  

  return (
    <Grid fluid className="center-container">
      <div className="signin-container">
        <a href="https://zipply.io">
          <Image src={logo} fluid loading="lazy" style={{ width: "300px", marginBottom: "20px" }}/>
        </a>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
          <Loader size="md" content="Signing in..."/>
        </div>
          </div>
    </Grid>
  );
}