import React, { useEffect, useState } from 'react';

import { Message } from 'rsuite';

type props = {
  error: any
  toaster: any
}

const RequestErrorMessage = ({ error, toaster }: props) => {
  const [alreadyRendered, setAlreadyRendered] = useState(false);

  useEffect(() => {
    if (!alreadyRendered) {
      setAlreadyRendered(true)
      toaster.push(<Message showIcon type='error' closable>{buildMessage()}</Message>, { placement: 'topCenter', duration: 20000 });
    }
  })

  function buildMessage() {
    let message = '';
    if ( typeof error === 'string' ) {
      message = error
    } else {
      if ( error.response ) {
        if (error.response.data) {
          const data = error.response.data
          if (data?.message) {
            message = data.message
          } else if (data?.status) {
            message = data.status
          } else if (data?.error) {
            message = data.error
          } else {
            message = 'Unexpected server error with status code ' + error.response.status;
          }
        } else {
          message = 'Unexpected server error with status code ' + error.response.status;
        }
      } else if (error.message) {
          message = 'Lost connectivity to Zipply server';
      } 
    }
    return message
  }

  return (<></>);
};

export default RequestErrorMessage;
