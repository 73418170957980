import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Configuration } from "utils/types";

const initialState: Configuration = { 
}

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfiguration(configuration: Configuration, action: PayloadAction<any>) {
    }
  }
});

export const { setConfiguration } = configurationSlice.actions;
export default configurationSlice;
