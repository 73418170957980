import React, { useEffect, useState } from 'react';

import { Col, Grid, Row } from 'rsuite';

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';
import founderImage from 'assets/images/founder.jpg';

interface Props {
  signIn?: boolean
  signUp?: boolean
  resetPassword?: boolean
}

export default function About({ signIn, signUp, resetPassword }: Props) {
  const [aboutMargin, setAboutMargin] = useState('150px');

  useEffect(() => {
    window.scrollTo(0, 0);

    function handleResize() {
      setAboutMargin('' + window.innerWidth / 10 + 'px');
    }

    window.addEventListener('resize', handleResize);    
  }, []);

  return (
    <div>
      <PublicHeader
        showSearch={false}
        signIn={signIn}
        signUp={signUp}
        resetPassword={resetPassword}
      />
      <Grid fluid>
        <Row style={{ marginTop: '20px', marginLeft: aboutMargin, marginRight: aboutMargin }}>
          <Col md={18} style={{ fontSize: '1.1rem', color: '#555' }}>
            <h1>About Zipply</h1>
            <p>Welcome to Zipply, where simplicity meets efficiency for everyday tasks. Zipply was born out of a simple wish: to find a quick, affordable way to get straightforward but time-consuming tasks done without the hassle of navigating overly complex platforms or spending hours finding the right help.</p>

            <h2>The Story Behind Zipply</h2>

            <p>I often found myself needing help with small, specific tasks – whether it was refining an image, getting help with a document, or doing small adjustments that anyone with a bit of skill could do. The challenge was that most services out there made the process too complicated. Searching through countless profiles, comparing prices, and even paying significant amounts upfront felt cumbersome for these simple needs. All I wanted was a straightforward platform where I could post my need, and talented people would be ready to jump in and solve it, getting paid fairly in the process.</p>

            <p>Zipply is my answer to that need. It’s a space designed to keep things simple, affordable, and accessible to everyone, whether you’re looking for help or offering it. Here, there’s no need to commit a large budget upfront – you simply set the reward you’re comfortable with, and solvers can quickly respond with their solutions. Zipply’s open model means both the task creator and solver can focus on what matters: getting tasks done, without unnecessary steps in between.</p>

            <h2>Why Zipply?</h2>

            <p>As powerful as AI can be, there are still many things it can’t do alone, and sometimes, I found myself spending more time trying to get an automated solution right than it would have taken for someone with the right expertise to just solve it. Zipply bridges that gap, bringing together a community where creativity, skill, and simplicity align.</p>

            <p>Thank you for joining me on this journey. Your support, feedback, and encouragement help make Zipply a space where people can quickly get help with tasks, showcase their skills, and make life a bit easier. I hope Zipply helps you as much as I know it can help others, making task-solving straightforward, affordable, and rewarding for everyone.</p>
          </Col>
          <Col md={6} style={{ padding: "30px" }}>
            <Row style={{ textAlign: 'left', margin: '2rem 0' }}>
              <img
                src={founderImage}
                style={{
                  width: '200px',
                  borderRadius: '15px', // Rounded corners
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Background shadow for the image
                  marginBottom: '1rem'
                }}
              />
              <Row>
                <p style={{ fontFamily: 'cursive', fontSize: '1.1rem', color: '#555' }}>
                  Andreas Bayer
                </p>
              </Row>
            </Row>
          </Col>
        </Row>
      </Grid>
      <PublicFooter />
    </div>
  );
};
