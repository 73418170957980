import { differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';

const getTimeRemaining = ( { endDate }: any ) => {
    const now = new Date();
    const daysLeft = differenceInDays(endDate, now);
    const hoursLeft = differenceInHours(endDate, now);
    const minutesLeft = differenceInMinutes(endDate, now);

    if (minutesLeft <= 0) {
      return "In Review";
    }

    if (daysLeft > 0) {
      return `${daysLeft} day${daysLeft > 1 ? 's' : ''} left`;
    } else if (hoursLeft > 0) {
      return `${hoursLeft} hour${hoursLeft > 1 ? 's' : ''} left`;
    } else {
      return `${minutesLeft} minute${minutesLeft > 1 ? 's' : ''} left`;
    }
  };

export const TimeRemaining = ( { endDate }: any ) => {
    return (
      <span>{getTimeRemaining({ endDate })}</span>
    );  
  };

  export default TimeRemaining;