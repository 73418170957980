import React, { useState, ReactNode } from 'react';

interface FileProps {
  name: string;
  url: string;
}

interface Props {
  file: FileProps;
}

const FilePreview = ({ file }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [previewContent, setPreviewContent] = useState<ReactNode | null>(null);

  if (!file) {
    return null;
  }

  // Determine the file type based on extension
  const getFileType = (fileName: string): 'image' | 'video' | 'text' | 'other' => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension || '')) {
      return 'image';
    } else if (['mp4', 'webm', 'ogg'].includes(extension || '')) {
      return 'video';
    } else if (['txt', 'csv'].includes(extension || '')) {
      return 'text';
    } else {
      return 'other';
    }
  };

  const handleMouseEnter = () => {
    const fileType = getFileType(file.name);

    switch (fileType) {
      case 'image':
        setPreviewContent(<img src={file.url} alt={file.name} style={{ maxWidth: '200px', maxHeight: '200px' }} />);
        break;
      case 'video':
        setPreviewContent(
          <video width="200" height="150" controls>
            <source src={file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
        break;
      case 'text':
        fetch(file.url)
          .then(response => response.text())
          .then(text => setPreviewContent(<pre style={{ maxWidth: '200px', maxHeight: '200px' }}>{text}</pre>))
          .catch(() => setPreviewContent(<span>Error loading text</span>));
        break;
      default:
        setPreviewContent(<span>Cannot preview this file type</span>);
        break;
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setPreviewContent(null);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <a
        href={file.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: '10px' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {file.name}
      </a>

      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            background: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            zIndex: 1000,
            maxWidth: '220px',
          }}
        >
          {previewContent}
        </div>
      )}
    </div>
  );
};

export default FilePreview;
