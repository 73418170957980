import React from 'react';

import { IconButton, Loader } from 'rsuite';
import RemoveIcon from '@rsuite/icons/WarningRound';
import CompleteIcon from '@rsuite/icons/CheckRound';

type Props = {
  fileList: any
  onRemove: (fileKey: string) => void
  isUploading: boolean
};

const FileUploadList = ({ fileList, onRemove, isUploading }: Props) => {
    return (
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {fileList.map((file: any) => (
          <li key={file.fileKey} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ flex: 1 }}>
              <strong>{file.name}</strong> - {Math.round(file.blobFile.size / 1024)} KB
            </div>
            <div
              style={{
                color: file.status.startsWith('error') ? 'red' : 'green',
                marginRight: '10px',
              }}
            >
              Status: {file.status}
            </div>
            <div>
              {file.status === 'uploading' &&
                <IconButton icon={<Loader size='xs'/>} appearance="subtle"/>
              }
              {file.status === 'finished' && 
                <IconButton icon={<CompleteIcon />} appearance="subtle"/>
              }              
              {file.status === 'selected' && !isUploading &&
                <IconButton icon={<RemoveIcon/ >} appearance="subtle" onClick={() => onRemove(file.fileKey)}/>
              }              
            </div>
          </li>
        ))}
      </ul>
    );
  };
  
  export default FileUploadList;