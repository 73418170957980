import { useEffect } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from 'utils/axios';

import { MDBCardImage } from 'mdb-react-ui-kit';
import { Loader, Panel, Rate, Grid, Row, Col, Divider, Avatar, Button } from 'rsuite';
import LocationIcon from '@rsuite/icons/Location';

import { putRequest, deleteRequest } from "utils/axios";

import { subYears } from "date-fns";

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';
import AwardedBadge from 'components/badges/AwardedBadge';
import ActivityCalendar from 'components/elements/ActivityCalendar';
import ZipplyEditor from 'components/editor/ZipplyEditor';

import FollowIcon from 'assets/images/zipply-follow-icon-48x48.png';
import UnFollowIcon from 'assets/images/zipply-unfollow-icon-48x48.png';


const PublicProfile = ({ username }: { username: any }) => {
    const upperValueBound = 100;

    const countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    const { data: profile, trigger: refreshProfile } = useSWRMutation<any>(`/users/${username}/handle/`, fetcher);

    useEffect(() => {
        refreshProfile();
    }, [username]);

    const startDate = subYears(new Date(), 1);
    const endDate = new Date();

    if (!profile) {
        return (
            <>
                <PublicHeader showSearch={false} signIn={false} signUp={false} resetPassword={false} />
                <Loader size="md" content="Fetching profile..." />
            </>
        );
    }

    function handleFollowRequest(isFollowing: boolean) {
        if (isFollowing) {
            deleteRequest(`/following/${profile.user_id}/`).then((response) => {
                if (response.status === 204) {
                    refreshProfile();
                }
            });
        } else {
            putRequest(`/following/${profile.user_id}/`).then((response) => {
                if (response.status === 201) {
                    refreshProfile();
                }
            });
        }
    }

    function FollowButton({ isFollowing }: { isFollowing: boolean }) {
        let iconToShow = FollowIcon;
        let textToShow = "Follow";
    
        if (isFollowing) {
            iconToShow = UnFollowIcon;
            textToShow = "Unfollow";
        }
        return (
          <Button appearance="link" onClick={() => handleFollowRequest(isFollowing)}>
            <img
              src={iconToShow}
              alt={textToShow + ' User'}
              style={{ marginRight: 2 }}
            />
            <span style={{ color: "#3d8a0a", fontWeight: "800", marginTop: "18px" }}>{textToShow}</span>
          </Button>
        );
      }    

    return (
        <div>
            <PublicHeader showSearch={false} signIn={false} signUp={false} resetPassword={false} />
            <Grid fluid>
                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <Panel>
                            {profile.image_url ?
                                <MDBCardImage src={profile.image_url} loading="lazy" position="top mb-2 rounded-circle" style={{ height: '250px', width: '250px' }} />
                                :
                                <Avatar circle style={{ height: '250px', width: '250px' }} />
                            }
                            <h2>{profile.display_name}</h2>
                            <h3 style={{ color: 'gray' }}>{username}</h3>
                            <p>Joined: {new Date(profile.created).toLocaleDateString()}</p>
                            <p><LocationIcon style={{ fontSize: '20px', color: '#000' }} /> {countries.getName(profile.country_iso, "en")}</p>
                            <p>Followers: {profile.follower_count}</p>
                            {profile.is_following != null &&
                                <FollowButton isFollowing={profile.is_following}/>
                            }
                        </Panel>
                    </Col>

                    <Col xs={24} sm={24} md={16}>
                        <Grid fluid>
                            <Row style={{ marginTop: "10px" }}>
                                <Panel bordered header={<h3>What I’m Good At</h3>}>
                                    {profile.expertise ?
                                        <ZipplyEditor key='expertise' content={profile.expertise} readOnly={true} height={200} />
                                        : 
                                        <>No information available.</>
                                    }
                                </Panel>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Panel bordered header={<h3>What I've Done So Far</h3>}>
                                    <Row>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong>Tasks&nbsp;Completed:</strong> {profile.tasks_paid || 0}
                                        </Col>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong> Positive Feedback as Tasker:</strong> {profile.tasker_positive_feedback ? (profile.tasker_positive_feedback * 100).toFixed(0) + '%' : 'No Feedback Yet'}
                                        </Col>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong>Tasker Rating:</strong> {profile.tasker_rating ? (<Rate color="blue" defaultValue={profile.tasker_rating} size="sm" allowHalf readOnly />) : 'No Rating Yet'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong>Solutions&nbsp;Accepted:</strong> {profile.solutions_accepted || 0}
                                        </Col>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong> Positive Feedback as Solver:</strong> {profile.solver_positive_feedback ? (profile.solver_positive_feedback * 100).toFixed(0) + '%' : 'No Feedback Yet'}
                                        </Col>
                                        <Col sm={24} md={24} lg={8}>
                                            <strong>Solver&nbsp;Rating:</strong> {profile.solver_rating ? (<Rate color="yellow" defaultValue={profile.solver_rating} size="sm" allowHalf readOnly />) : 'No Rating Yet'}
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        <Col xs={24}>
                                            <strong>Last 12 Month Activity</strong>
                                            <ActivityCalendar values={profile.activity_history} startDate={startDate} endDate={endDate} upperValueBound={upperValueBound}/>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Panel bordered header={<h3>My Achievements</h3>}>
                                    <Row>
                                        {profile.badges.length > 0 ? profile.badges.map((badge: any, index: number) => (
                                            <Col>
                                                <AwardedBadge badge={badge} />
                                            </Col>
                                        )) : (
                                            <Col xs={24}>
                                                <li>No badges earned yet.</li>
                                            </Col>
                                        )}
                                    </Row>
                                </Panel>
                            </Row>
                        </Grid>
                    </Col>
                </Row>
            </Grid>
            <PublicFooter />
        </div>
    );
};

export default PublicProfile;
