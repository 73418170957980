import { useState } from 'react';
import { putRequest } from "utils/axios";
import { Modal, Button, Form, FlexboxGrid, useToaster } from 'rsuite';

import TextArea from 'components/elements/Textarea';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';

interface Props {
    open: boolean;
    close: () => void;
    taskId: string;
}

const DeclineAllSolutionsReasonModal = ({ open, close , taskId} : Props ) => {
    const toaster = useToaster();
    const [reason, setReason] = useState(''); 

    const handleReaonSubmit = () => {
        if (reason === '') {
            toaster.push(<RequestErrorMessage error="Please provide a reason for declining all solutions." toaster={toaster} />);
            return;
        }
        putRequest(`/tasks/${taskId}/decline-all/`, { reason }).then((res) => {
            toaster.push(<RequestSuccessMessage message={'Your request to decline all solutions has been successfully submitted. Please allow up to 24 hours for our team to review and provide a decision.'} toaster={toaster} />)
            handleClose();
        }).catch((error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        })
    };

    const handleClose = () => {
        setReason('');
        close();
    };

    return (
        <Modal size="sm" open={open} onClose={handleClose} backdrop="static">
            <Modal.Header>
                <Modal.Title>Reason for Declining All Solutions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{color: 'red', fontSize: "12px"}}>Declining all submitted solutions is a serious action that should be exercised with caution, as it also acts as a request to cancel the task. We require a clear, valid reason for rejecting all solutions, as our team will carefully review this decision before any refund is issued. If approved, a refund will be processed minus applicable convenience fees. Please be aware that unwarranted declines can impact both solver engagement and the overall experience on the platform. Only request a cancellation if no submitted solutions are appropriate for the task's purpose.</p>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={24}>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel><strong>Leave a reason</strong></Form.ControlLabel>
                                <Form.Control
                                    accepter={TextArea}
                                    name="textarea"
                                    rows={3}
                                    placeholder="Please share a reason for declining all solution."
                                    value={reason}
                                    onChange={(value) => setReason(value)}
                                />
                            </Form.Group>
                        </Form>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Body>

            <Modal.Footer>
                <Button appearance="primary" onClick={handleReaonSubmit}>
                    Submit Request
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeclineAllSolutionsReasonModal;
