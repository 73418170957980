import { useSelector } from "react-redux";
import { RootState } from "store";
import {Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let auth = useSelector((state: RootState) => state.auth);
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the / page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they sign in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/home" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

export default ProtectedRoute;