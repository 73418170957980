import React, { useEffect, useState } from "react";

import { putRequest, deleteRequest } from "utils/axios";
import { Modal, Button, Stack, Grid, Row, Divider, useToaster, Loader, Pagination, Badge, Dropdown, Tabs } from 'rsuite';

import MessageIcon from '@rsuite/icons/Message';
import DropdownIcon from '@rsuite/icons/legacy/EllipsisV';

import SolutionFeedbackModal from "components/feedback/SolutionFeedbackModal";
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import TaskMessaging from 'components/communication/TaskMessaging';
import SolutionDetails from "components/solutions/SolutionDetails";
import TaskDetails from 'components/tasks/TaskDetails';
import SolutionRejectionReasonModal from 'components/solutions/SolutionRejectionReasonModal';
import DeclineAllSolutionsReasonModal from 'components/solutions/DeclineAllSolutionsReasonModal';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import ZipplyEditor from 'components/editor/ZipplyEditor';
import FilePreview from "components/files/FilePreview";
import { getRequest } from "utils/axios";

interface Props {
  open: boolean;
  taskId: string;
  close: () => void;
}

export default function TaskerSolutionsModal({ open, taskId, close }: Props) {
  const toaster = useToaster();
  const [solutions, setSolutions] = useState<any>([]);
  const [currentSolution, setCurrentSolution] = useState<any>(null);
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState<number>(1);
  const [solutionFeedbackModalOpen, setSolutionFeedbackModalOpen] = useState<boolean>(false);
  const [taskMessagingModal, setTaskMessagingModalOpen] = useState<boolean>(false);
  const [solutionRejectionReasonModalOpen, setSolutionRejectionReasonModalOpen] = useState<boolean>(false);
  const [declineAllSolutionsModalOpen, setDeclineAllSolutionsModalOpen] = useState<boolean>(false);
  const [showAcceptConfirmationDialog, setShowAcceptConfirmationDialog] = useState<boolean>(false);
  const [extendedDueDate, setExtendedDueDate] = useState<any>();
  const [task, setTask] = useState<any>();
  const [tabActiveKey, setTabActiveKey] = useState<any>('solutions');

  useEffect(() => {
    if (taskId) {
      refreshTask();
    }
  }, [open, taskId]);

  const refreshTask = () => {
    getRequest(`tasks/${taskId}/`).then((res: any) => {
      setTask(res.data);
      setSolutions(res.data.solutions || []);
      setExtendedDueDate(new Date(res.data.due_date));
      if (res.data.solutions && res.data.solutions.length > 0) {
        setCurrentSolutionIndex(1);
        setCurrentSolution(res.data.solutions[0]);
      }
    }
    );
  }

  const handleClose = () => {
    close();
    setShowAcceptConfirmationDialog(false);
    setTabActiveKey('solutions');
  };

  function handleAcceptSolution() {
    putRequest(`/solutions/${currentSolution.id}/accept/`).then((res) => {
      setSolutionFeedbackModalOpen(true);
      toaster.push(<RequestSuccessMessage message={'Congratulations! You successfully accepted the solution.'} toaster={toaster} />)
      refreshTask()
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    })
  }

  function handleInviteSolver() {
    putRequest(`/solutions/${currentSolution.id}/invite/`).then((res) => {
      toaster.push(<RequestSuccessMessage message={'You successfully invited the solver.'} toaster={toaster} />)
      refreshTask()
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    })
  }

  function handleDeclineSolver() {
    putRequest(`/solutions/${currentSolution.id}/decline/`).then((res) => {
      toaster.push(<RequestSuccessMessage message={'You successfully declined the solver.'} toaster={toaster} />)
      refreshTask()
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    })
  }

  function handleSolutionChange(dataKey: number) {
    setCurrentSolutionIndex(dataKey);
    setCurrentSolution(solutions[dataKey - 1]);
  }

  function handleCancelTask() {
    deleteRequest(`/tasks/${task.id}/`).then((res) => {
      handleClose();
      toaster.push(<RequestSuccessMessage message={'You successfully canceled the task.'} toaster={toaster} />)
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    })
  }

  function handleSolutionFeedbackClose() {
    setSolutionFeedbackModalOpen(false);
    handleClose();
  }

  function hasSolutions() {
    return solutions && solutions.length > 0;
  }

  function handleRejectSolution() {
    if (task.can_request_cancelation) {
      setSolutionRejectionReasonModalOpen(true)
    } else {
      toaster.push(<RequestErrorMessage error={'Unable to reject solutions: Your previous request to cancel the task by declining all solutions was not approved.'} toaster={toaster} />);
    }
  }

  function handleSolutionRejectionClose() {
    setSolutionRejectionReasonModalOpen(false);
    refreshTask()
  }

  function handleDeclineAllSolutions() {
    if (task.can_request_cancelation) {
      setDeclineAllSolutionsModalOpen(true);
    } else {
      toaster.push(<RequestErrorMessage error={'Unable to decline all solutions: Your previous request to cancel the task by declining all solutions was not approved.'} toaster={toaster} />);
    }
  }

  function handleDeclineAllSolutionsClose() {
    setDeclineAllSolutionsModalOpen(false);
    refreshTask()
  }

  function handleTaskMessagingClose() {
    setTaskMessagingModalOpen(false);
    handleClose()
  }

  function canRepostWithNewDueDate(due_date: any) {
    return new Date() > new Date(due_date);
  }

  function handleRepostWithNewDueDate(due_date: any) {
    if (extendedDueDate <= new Date(due_date)) {
      toaster.push(<RequestErrorMessage error={'Please choose a new due date that is at least one day beyond the current due date before reposting the task.'} toaster={toaster} />);
      return;
    }
    putRequest(`/tasks/${task.id}/`, {
      due_date: extendedDueDate
    }).then((res) => {
      handleClose();
      toaster.push(<RequestSuccessMessage message={'You successfully reposted the task.'} toaster={toaster} />)
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    })
  }

  return (<>
    <SolutionRejectionReasonModal solution={currentSolution} open={solutionRejectionReasonModalOpen} close={handleSolutionRejectionClose} />
    <DeclineAllSolutionsReasonModal taskId={taskId} open={declineAllSolutionsModalOpen} close={handleDeclineAllSolutionsClose} />

    <TaskMessaging
      open={taskMessagingModal}
      task={task}
      close={handleTaskMessagingClose}
      role="tasker"
    />

    <SolutionFeedbackModal open={solutionFeedbackModalOpen} solution={currentSolution} close={handleSolutionFeedbackClose} />
    <Modal size="lg" open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Submitted Task Solutions</Modal.Title>
      </Modal.Header>
      {!task ? (
        <Loader size="md" content="Fetching task details..." />
      ) : (
        <>
          <Modal.Body>
            <TaskDetails task={task} showDetails={false} showSolutionDetails={false} showNetAmount={false} extendedDueDate={extendedDueDate} setExtendedDueDate={setExtendedDueDate} />

            <Tabs activeKey={tabActiveKey} onSelect={setTabActiveKey} id="tabs" style={{ marginTop: "20px" }}>
              {task.current_status !== 'CANCELED' && task.current_status !== 'CANCEL REQUEST' &&
                <Tabs.Tab eventKey="solutions" title="Solutions">
                  <div style={{ height: "400px" }}>
                
                    {solutions && solutions.length > 0 && currentSolution ?
                      <SolutionDetails solution={currentSolution} showSolver={true} />
                      :
                      <span>No solutions available</span>
                    }
                  </div>
                </Tabs.Tab>
              }

              <Tabs.Tab eventKey="task-description" title="Task Overview">
                <div style={{ height: "400px" }}>
                  <ZipplyEditor key={task.id + "-task-description"} content={task.description} readOnly={true} height={350} />
                </div>
              </Tabs.Tab>

              {task.protected_description && (
                <Tabs.Tab eventKey="task-protected-description" title="Task Description">
                  <div style={{ height: "400px" }}>
                    <ZipplyEditor key={task.id + "-task-protected-description"} content={task.protected_description} readOnly={true} height={350} />
                  </div>
                </Tabs.Tab>
              )}

              {task.attached_files && task.attached_files.length > 0 && (
                <Tabs.Tab eventKey="task-files" title="Task Files">
                  <div style={{ height: "400px" }}>
                        <ul>
                          {task.attached_files.map((file: any, index: number) => (
                            <li><FilePreview file={file} /></li>
                          ))
                          }
                        </ul>
                  </div>
                </Tabs.Tab>
              )}
            </Tabs>

          </Modal.Body>

          {task && task.current_status !== 'CANCELED' && task.current_status !== 'CANCEL REQUEST' &&
            <Modal.Footer>
              <Divider />
              <Stack justifyContent="space-between" spacing={10}>
                <Stack spacing={10} justifyContent="flex-start">
                  {task?.can_post_message &&
                    <Stack.Item>
                      {task?.unread_message_count > 0 ?
                        <Badge>
                          <MessageIcon onClick={() => setTaskMessagingModalOpen(true)} />
                        </Badge>
                        :
                        <MessageIcon onClick={() => setTaskMessagingModalOpen(true)} />
                      }
                    </Stack.Item>
                  }
                  <Stack.Item>
                    <div style={{ marginTop: '10px' }}>
                      Total Solutions: {solutions.length}
                    </div>
                  </Stack.Item>
                </Stack>
                <Stack.Item>
                  {solutions && solutions.length > 0 ? (
                    <Stack justifyContent="space-between" spacing={10}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="sm"
                        layout={['pager']}
                        total={solutions.length}
                        limit={1}
                        activePage={currentSolutionIndex}
                        onChangePage={handleSolutionChange}
                      />
                      {currentSolution?.current_status === 'REQUESTED INVITATION' ? (<>
                        <Button onClick={handleInviteSolver} color="green" appearance="primary">Invite</Button>
                        <Button onClick={handleDeclineSolver} color="red" appearance="primary">Decline</Button>
                        {task && canRepostWithNewDueDate(task.due_date) && <Button onClick={() => handleRepostWithNewDueDate(task.due_date)} color="green" appearance="primary">Repost</Button>}
                      </>) : (<>
                        {task.can_accept && (<>
                          <ConfirmationDialog open={showAcceptConfirmationDialog} handleClose={() => setShowAcceptConfirmationDialog(false)} handleConfirm={handleAcceptSolution} title="Confirm Solver Acceptance">
                            <div style={{ fontSize: "12px" }}>
                              <p>Before proceeding, please confirm your acceptance of this solution to ensure a smooth experience for both taskers and solvers.</p>

                              <p><strong>Important Note:</strong> Once you accept a solution, this decision is final. The reward will be released to the solver, and no modifications or cancellations will be possible after confirmation.</p>

                              <p>Please confirm your understanding and intent by clicking the Confirm button below.</p>
                            </div>
                          </ConfirmationDialog>
                          <Button onClick={() => setShowAcceptConfirmationDialog(true)} color="green" appearance="primary" disabled={!hasSolutions() || currentSolution && currentSolution?.current_status === 'REJECTED'}>Accept</Button>
                        </>)}
                        {task && canRepostWithNewDueDate(task.due_date) && <Button onClick={() => handleRepostWithNewDueDate(task.due_date)} color="green" appearance="primary">Repost</Button>}
                        {task && task.current_status === 'POSTED' && task.can_request_cancelation &&
                          <Dropdown title="More Options" placement="bottomEnd" renderToggle={(props, ref) => (
                            <Button {...props} ref={ref} appearance="link">
                              <DropdownIcon />
                            </Button>
                          )}>
                            {currentSolution?.current_status !== 'REQUESTED INVITATION' &&
                              <Dropdown.Item onClick={handleRejectSolution}>Reject Solution</Dropdown.Item>
                            }
                            <Dropdown.Item onClick={handleDeclineAllSolutions}>Decline All Solutions</Dropdown.Item>
                          </Dropdown>
                        }
                      </>)}
                    </Stack>
                  ) : (
                    <>
                      {task && task.can_cancel && <Button onClick={handleCancelTask} color="red" appearance="primary">Cancel Task</Button>}
                      {task && !task.can_cancel && task.can_request_cancelation && <Button onClick={handleDeclineAllSolutions} color="red" appearance="primary">Request Task Cancelation</Button>}
                      {task && canRepostWithNewDueDate(task.due_date) && <Button onClick={() => handleRepostWithNewDueDate(task.due_date)} color="green" appearance="primary">Repost</Button>}
                    </>
                  )}
                </Stack.Item>
              </Stack>
            </Modal.Footer>
          }
        </>
      )}
    </Modal>
  </>);
}
