import { useState, useEffect, useRef } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { postRequest } from "utils/axios";

import { Modal, Button, Form, FlexboxGrid, List, useToaster } from 'rsuite';

import TextArea from 'components/elements/Textarea';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';

type Message = {
    id: string;
    poster: {
        id: string;
        display_name: string;
        username: string;
    };
    created: string;
    message: string;
};

type Post = {
    id: string;
    message: string;
    created: Date;
};

type Question = {
    id: string;
    message: string;
    displayName: string;
    username: string;
    userId: string;
    created: Date;
};


interface Props {
    open: boolean;
    close: () => void;
    task: any;
    role: string;
}

const TaskMessaging = ({ open, close, task, role }: Props) => {
    const toaster = useToaster();

    const placeHolderMessage = role === 'tasker' ? 'Post an update...' : 'Ask a question...';
    // Sample data for demonstration
    const [posts, setPosts] = useState<Post[]>([]);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [newPost, setNewPost] = useState('');
    const user: any = useSelector((state: RootState) => state.auth.user);

    const postsEndRef = useRef<HTMLDivElement | null>(null);

    const { data: messages, trigger: refreshMessages } = useSWRMutation<any>(`/tasks/${task?.id}/messages/`, fetcher, 
        {
          onSuccess: (messages: any) => {
            if (messages.count > 0) {
                const { posts, questions } = separateMessagesByTasker(messages.results, task.tasker.id);
                setPosts(posts);
                setQuestions(questions);
                if (postsEndRef.current) {
                    postsEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
          }
        }
      );

    function separateMessagesByTasker(
        messages: Message[],
        taskerId: string
    ): { posts: Post[]; questions: Question[] } {
        const posts: Post[] = [];
        const questions: Question[] = [];
        messages.forEach((msg) => {
            if (msg.poster.id === taskerId) {
                // If poster's ID matches the taskerId, it's a Post
                posts.push({
                    id: msg.id,
                    message: msg.message,
                    created: new Date(msg.created),
                });
            } else {
                // Otherwise, it's a Question
                questions.push({
                    id: msg.id,
                    message: msg.message,
                    displayName: msg.poster.display_name,
                    username: msg.poster.username,
                    userId: msg.poster.id,
                    created: new Date(msg.created),
                });
            }
        });
        // Sort both arrays by the 'created' timestamp
        posts.sort((a, b) => a.created.getTime() - b.created.getTime());
        questions.sort((a, b) => a.created.getTime() - b.created.getTime());
    
        return { posts, questions };
    }      
    
    const handlePostMessage = () => {
        if (newPost === '') {
            return
        }
        const message = {
            message: newPost,
            task_id: task.id,
            poster: user.id,
        };

        postRequest(`/tasks/${task.id}/messages/`, message)
        .then((res) => {
            refreshMessages()
            setNewPost('');
        })
        .catch((err) => {
          toaster.push(<RequestErrorMessage error={err} toaster={toaster} />)
        });        
    };

    const handleClose = () => {
        close();
    };

    useEffect(() => {
        if (open && task) {
            refreshMessages()
        }
    }, [open, task]);

    return (
        <Modal size="lg" open={open} onClose={handleClose} backdrop="static">
            <Modal.Header>
                <Modal.Title>Task Messaging for: {task?.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                <p style={{fontSize: "12px", color: "#FD7E14", fontWeight: "800"}}>Questions submitted by solvers are private and only visible to the tasker. However, responses or updates from the tasker are visible to all solvers.</p>
                <FlexboxGrid style={{ flexGrow: 1, overflow: 'hidden' }}>
                    <FlexboxGrid.Item colspan={12} style={{ height: '100%', overflowY: 'auto' }}>
                        {role === 'tasker' ? <h4>My Posts</h4> : <h4>Posts from Tasker</h4>}
                        {posts.length === 0 ?  
                            <p>No posts yet.</p> 
                            :
                            <List bordered style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                {posts.map((post) => (
                                    <List.Item key={post.id}>
                                        {post.message}
                                        <div style={{ fontSize: '12px', color: '#999' }}>
                                            {new Date(post.created).toLocaleString()}
                                        </div>
                                    </List.Item>
                                ))}
                                {/* Reference element for scrolling to the latest post */}
                                <div ref={postsEndRef} />
                            </List>
                        }
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={12} style={{ height: '100%', overflowY: 'auto' }}>
                        {role === 'tasker' ? <h4>Questions from Solvers</h4> : <h4>My Questions</h4>}
                        {questions.length === 0 ? 
                            <p>No questions yet.</p>
                            :
                            <List bordered style={{ maxHeight: 'calc(60vh - 100px)', overflowY: 'auto' }}>
                                {questions.map((question) => (
                                    <List.Item key={question.id}>
                                        {role === 'tasker' && <strong>{question.displayName}:</strong>} {question.message}
                                        <div style={{ fontSize: '12px', color: '#999' }}>
                                            {new Date(question.created).toLocaleString()}
                                        </div>
                                    </List.Item>
                                ))}
                            </List>
                        }
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Body>

            <Modal.Footer>
                <Form fluid style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Control
                        accepter={TextArea}
                        name="postTextarea"
                        rows={1}
                        style={{ flexGrow: 1 }}
                        placeholder={placeHolderMessage}
                        value={newPost}
                        onChange={(value) => setNewPost(value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                              e.preventDefault(); // Prevent new line
                              handlePostMessage(); // Submit form
                            }
                          }}
                    />
                    <Button
                        appearance="primary"
                        onClick={handlePostMessage}
                        style={{ marginLeft: 10 }}>
                        {role === 'tasker' ? 'Post Update' : 'Ask Question'}
                    </Button>
                </Form>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskMessaging;
