import { useEffect, useState } from 'react';
import { Stack, Grid, Row, Tabs } from 'rsuite';
import { format } from 'date-fns';
import DueDatePicker from 'components/elements/DueDatePicker';

import FilePreview from "components/files/FilePreview";
import TimeRemaining from 'components/elements/TimeRemaining';
import ZipplyEditor from 'components/editor/ZipplyEditor';
import SolutionDetails from "components/solutions/SolutionDetails";
import { getRequest } from 'utils/axios';
import { displayCentAmountInDollars } from 'utils/utils';

interface Props {
  taskId?: any
  task: any
  showDetails: boolean
  showNetAmount: boolean
  showSolutionDetails: boolean
  extendedDueDate?: any
  setExtendedDueDate?: (newDueDate: any) => void
}

export default function TaskDetails({ taskId, task, showDetails, showSolutionDetails, showNetAmount, extendedDueDate, setExtendedDueDate }: Props) {
  const [displayTask, setDisplayTask] = useState<any>(null);
  const [tabActiveKey, setTabActiveKey] = useState<any>(showSolutionDetails ? 'solution' : 'task-description');

  useEffect(() => {
    if (taskId && !task) {
      getRequest(`tasks/${taskId}/`).then((res) => {
        if (res.status === 200) {
          setDisplayTask(res.data);
          if (res.data.current_status === 'CANCELED' || res.data.current_status === 'CANCEL REQUEST' || res.data.current_status === 'HOLD') {
            setTabActiveKey('task-description');
          } else {
            setTabActiveKey(showSolutionDetails ? 'solution' : 'task-description');
          }
        }
      });
    } else {
      setDisplayTask(task);
      if (task.current_status === 'CANCELED' || task.current_status === 'CANCEL REQUEST' || task.current_status === 'HOLD') {
        setTabActiveKey('task-description');
      } else {
        setTabActiveKey(showSolutionDetails ? 'solution' : 'task-description');
      }
    }
  }, [taskId, task]);

  if (!displayTask) {
    return <div>Loading...</div>
  }

  let rendered_reward = null
  if (showNetAmount) {
    rendered_reward = displayCentAmountInDollars(displayTask.reward_in_cents - displayTask.zipply_fee_in_cents);
  } else {
    rendered_reward = displayCentAmountInDollars(displayTask.reward_in_cents);
  }

  const isPastDue = (dueDate: any) => {
    return new Date(dueDate) < new Date();
  }

  return (
    <Grid fluid>
      <Row>
        <Stack spacing={10} justifyContent="space-between">
          <Stack.Item>
            <strong>Title: </strong>
            <span>{displayTask.title}</span>
          </Stack.Item>
          <Stack.Item>
            <strong>Reward: </strong>
            <span>{rendered_reward}</span>
          </Stack.Item>
        </Stack>
        <Stack spacing={20} justifyContent="space-between" alignItems='flex-start' style={{ marginTop: "15px" }}>
          <Stack.Item>
            <strong>Accept Solutions Early: </strong><br></br>
            <span>{displayTask.accept_solutions_early ? 'Yes' : 'No'}</span>
          </Stack.Item>
          <Stack.Item>
            <strong>By Invite Only: </strong><br></br>
            <span>{displayTask.invite_only ? 'Yes' : 'No'}</span>
          </Stack.Item>
          <Stack.Item>
            {extendedDueDate && setExtendedDueDate && isPastDue(displayTask.due_date) ?
              <span><strong>Past Due Date: </strong><DueDatePicker dueDate={extendedDueDate} setDueDate={setExtendedDueDate} /><TimeRemaining endDate={new Date(displayTask.due_date)} /></span>
              :
              <span><strong>Due Date: </strong><br></br>{format(new Date(displayTask.due_date), 'MMMM dd, yyyy hh:mm aa')} - <TimeRemaining endDate={new Date(displayTask.due_date)} /></span>
            }
          </Stack.Item>
        </Stack>
        {showDetails && (
          <Tabs activeKey={tabActiveKey} onSelect={setTabActiveKey} id="tabs" style={{ marginTop: "20px" }}>
            {showSolutionDetails && displayTask.solutions && displayTask.current_status !== 'CANCELED' && displayTask.current_status !== 'CANCEL REQUEST' && (
              <Tabs.Tab eventKey="solution" title="Solution">
                <div style={{ height: "400px" }}>
                  <SolutionDetails solution={displayTask.solutions[0]} showSolver={true} />
                </div>
              </Tabs.Tab>
            )}
            <Tabs.Tab eventKey="task-description" title="Task Overview">
              <div style={{ height: "400px" }}>
                <ZipplyEditor key={displayTask.id + '-description'} content={displayTask.description} readOnly={true} height={200} />
              </div>
            </Tabs.Tab>

            {displayTask.protected_description &&
              <Tabs.Tab eventKey="task-protected-description" title="Task Description">
                <div style={{ height: "400px" }}>
                  {displayTask.protected_description && (<div style={{ marginTop: '10px' }}>
                    <ZipplyEditor key={displayTask.id + '-protected-description'} content={displayTask.protected_description} readOnly={true} height={200} />
                  </div>)}
                </div>
              </Tabs.Tab>
            }

            {displayTask.attached_files && displayTask.attached_files.length > 0 && (
            <Tabs.Tab eventKey="task-files" title="Task Files">
              <div style={{ height: "400px" }}>
                    <ul>
                      {displayTask.attached_files.map((file: any, index: number) => (
                        <li><FilePreview file={file} /></li>
                      ))
                      }
                    </ul>
              </div>
            </Tabs.Tab>
          )}
          </Tabs>
        )
        }
      </Row>
    </Grid>
  );
}
