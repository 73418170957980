import React, { useEffect, useState } from 'react';

import { Message } from 'rsuite';

type props = {
  message: string
  toaster: any
}

const RequestConflictMessage = ({ message, toaster }: props) => {
  const [alreadyRendered, setAlreadyRendered] = useState(false);

  useEffect(() => {
    if (!alreadyRendered) {
      setAlreadyRendered(true)
      toaster.push(<Message showIcon type='warning' closable>{message}</Message>, { placement: 'topCenter', duration: 20000 });
    }
  })
  return (<></>);
};

export default RequestConflictMessage;
