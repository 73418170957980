import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { useNavigate, Link } from "react-router-dom";
import { getRequest, postRequest } from "utils/axios";
import zxcvbn from "zxcvbn";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, useToaster, Button, Stack, Grid, Row, Col, Loader, InputGroup } from 'rsuite';

import ShowPasswordIcon from '@rsuite/icons/legacy/Eye';
import HidePasswordIcon from '@rsuite/icons/legacy/EyeSlash';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import Image from 'react-bootstrap/Image';

import { sendGAUserSignUp } from 'components/tracker/GoogleAnalyticsTracker';

import logo from "assets/images/zipply-logo-text-200x100.png"
//const logo = "https://via.placeholder.com/300x50.png?text=Logo";
import splash from "assets/images/zipply-splash.png"
//const splash = "https://via.placeholder.com/512x512.png?text=Splash";

interface Props {
  open: boolean
  handleClose: () => void
}

export default function SignUpModal({ open, handleClose }: Props) {
  const googleAuthorizationUrl = process.env.REACT_APP_API_URL + '/auth/social/google/login/'

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [performVerification, setPerformVerification] = useState(false);
  const [codeIncomplete, setCodeIncomplete] = useState(true);
  const [attemptToJoin, setAttemptToJoin] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    specialChar: false,
    number: false,
    strength: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toaster = useToaster(); 

  const handleSignUp = (recaptcha: any, email: any, password: any, firstName: any, lastName: any, consent: boolean) => {
    const username = email;
    setLoading(true);
    postRequest('/auth/register/', {
            "first_name": firstName, 
            "last_name": lastName, 
            "recaptcha": recaptcha,
            email, 
            password,
            consent_given: consent
        })
      .then((res) => {
        setVerifyCode(true);
        if (res.status === 201) {
          setPerformVerification(true);
        }
      })
      .catch((err) => {
        if (err.response.data && err.response.data.status && err.response.data.status.length > 0) {
          setMessage(err.response.data.status[0]);
        } else {
          setMessage("The email entered does not satisfy Zipply's requirements. Consider signing up with Google.");
        }
      }).finally(() => {
        setLoading(false);
      })
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    password: Yup.string().trim()
      .required("Password is required.")
      .test("is-valid-password", "Password must meet all criteria.", (value) => {
        return Object.values(passwordCriteria).every(Boolean)
      }),
    firstName: Yup.string().trim().required("First name is required."),
    lastName: Yup.string().trim().required("Last name is required."),
    consent: Yup.boolean().oneOf([true], "You must accept the Terms of Service and Privacy Policy to sign up.")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      consent: false
    },
    onSubmit: (values) => {
      const criteriaMet = Object.values(passwordCriteria).every(Boolean);
      if (criteriaMet) {
        if (process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY) {
          setShowRecaptcha(true);
        } else {
          handleSignUp(null, formik.values.email, formik.values.password, formik.values.firstName, formik.values.lastName, formik.values.consent);
        }
      }
    },
    validationSchema: validationSchema,
  });

  const handleConsentChange = (e: any) => {
    const consent = e.target.value;
    formik.setFieldValue("consent", consent === "on" ? true : false);
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    formik.setFieldValue("password", password);
    const result = zxcvbn(password);

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /[0-9]/;

    // This must be in sync with backend password validation
    const criteria = {
      length: password.length >= 8,
      specialChar: specialCharRegex.test(password),
      number: numericRegex.test(password),
      strength: true // TODO use later again: result.score >= 3,
    };
    
    setPasswordCriteria(criteria);
    setMessage("");
  };

  const renderPasswordCriteria = () => {
    const criteria = [
      { label: "At least 8 characters", met: passwordCriteria.length },
      { label: "At least one special character", met: passwordCriteria.specialChar },
      { label: "At least one numeric character", met: passwordCriteria.number },
      /* TODO use later again: { label: "Strong password", met: passwordCriteria.strength }, */
    ];

    return (
      <ul style={{ marginLeft: "-10px", listStyleType: "none", fontSize: "14px" }}>
        {criteria.map((criterion, index) => (
          <li key={index} style={{ color: criterion.met ? "green" : "red" }}>
            {criterion.met ? "✔️" : "❌"} {criterion.label}
          </li>
        ))}
      </ul>
    );
  };

  const handleRecaptchaChange = (value: any) => {
    if (value) {
      handleSignUp(value, formik.values.email, formik.values.password, formik.values.firstName, formik.values.lastName, formik.values.consent);
      setShowRecaptcha(false);
    } else {
      setMessage("Please complete the reCAPTCHA.");
    }
  };

  function requestAnotherConfirmationCode() {
    if (performVerification) {
      const email = formik.values.email

      postRequest('/auth/generate/', { email, type: 'registration' })
      .then((res) => {
        toaster.push( <RequestSuccessMessage message={'A new code has been sent.'} toaster={toaster}/> )
      })
      .finally(() => {
        clearCodeInput();
      });
    } else {
      toaster.push( <RequestSuccessMessage message={'A new code has been sent.'} toaster={toaster}/> )
      clearCodeInput();
    }
  }
  
  function verifyConfirmationCode() {
    if (performVerification) {
      const username = formik.values.email
      
      const digits = [];
      for (let i = 1; i <= 6; i++) {
        const element = document.getElementById(`digit${i}`) as HTMLInputElement | null;
        if (element) {
          digits.push(element.value);
        }
      }
      const verification_code = digits.join('');
      setLoading(true);
      postRequest('/auth/login/', { username, verification_code, type: 'registration' })
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setUser(res.data.user));
        const configurationPromise = getRequest('/system/configurations/ui/')
        configurationPromise.then((response) => {
          dispatch(configurationSlice.actions.setConfiguration(response.data));
        })   
        setLoading(false);
        sendGAUserSignUp(res.data)
        navigate("/", {
          state: {
            userId: res.data.id
          }
        });
      })
      .catch((err) => {
        toaster.push( <RequestErrorMessage error="The verification code you've entered is incorrect." toaster={toaster}/> )
      })
      .finally(() => {
        setLoading(false);
        clearCodeInput();
      });
    } else {
      toaster.push( <RequestErrorMessage error="The verification code you've entered is incorrect." toaster={toaster}/> )
      clearCodeInput();
    }
  }

  const moveToNext = (current: any, nextFieldID: any) => {
    if (current.value.length >= current.maxLength) {
      if (nextFieldID) {
        const nextField = document.getElementById(nextFieldID)
        if (nextField) {
          nextField.focus()
        }
      }
    }
    areAllDigitsEntered()
  };

  const areAllDigitsEntered = () => {
    setCodeIncomplete(false);
    const verificationForm = document.getElementById('verificationForm');
    if (verificationForm) {
        const inputs = verificationForm.querySelectorAll('input');
        if (inputs.length !== 6) {
          setCodeIncomplete(true);
        }
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value.trim() === '' || inputs[i].value.length !== 1) {
              setCodeIncomplete(true);
            }
        }
    } else {
      setCodeIncomplete(true);
    }
  };

  const clearCodeInput = () => {
    setCodeIncomplete(true);
    const verificationForm = document.getElementById('verificationForm');

    if (verificationForm) {
      const inputs = verificationForm.querySelectorAll('input');
      inputs.forEach((input: any) => {
          input.value = '';
      });
    }
  };

  const enableToPasteCode = () => {
    const verificationForm = document.getElementById('verificationForm');

    if (verificationForm) {
      const handlePaste = (e: ClipboardEvent) => {
        const paste = e.clipboardData?.getData('text');
        if (paste && /^\d{6}$/.test(paste)) {
          const inputs = verificationForm.querySelectorAll('input');
          inputs.forEach((input: any, index: any) => {
            input.value = paste[index];
          });
          inputs[inputs.length - 1].focus();
        }
        e.preventDefault();
        areAllDigitsEntered()
      };

      if (verificationForm) {
        verificationForm.addEventListener('paste', handlePaste);
      }

      // Cleanup the event listener on component unmount
      return () => {
        if (verificationForm) {
          verificationForm.removeEventListener('paste', handlePaste);
        }
      }
    }
  }

  return (
    <Modal size="lg"
        open={open}
        onClose={handleClose}>
    <Grid fluid>
      <Row>
          <Col xsHidden={true} smHidden={true} mdHidden={true} lg={16} xl={16} xxl={16}>
            <div className="center-container" style={{paddingLeft: "20px"}}>
            <img src={splash} loading="lazy" style={{ width: "490px", height: "490px", borderRadius: "20px", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="center-container">
            {verifyCode ? 
             <>
               <a href="https://zipply.io">
                <Image src={logo} fluid loading="lazy" style={{ marginBottom: "20px" }}/>
               </a>
               <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                    <p>
                        Please enter the verification code sent to your email address <strong>{formik.values.email}</strong>
                    </p>
               </div>
               {loading &&
                      <div style={{position: "absolute", paddingLeft: "50px"}}>
                          <Loader size="lg"/>
                      </div>
                    }
               <div className="verification-container" style={{ textAlign: "center" }}>
                  <form id="verificationForm" onSelect={enableToPasteCode}>
                      <input type="text" id="digit1" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit2')} />
                      <input type="text" id="digit2" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit3')} />
                      <input type="text" id="digit3" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit4')} />
                      <input type="text" id="digit4" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit5')} />
                      <input type="text" id="digit5" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit6')} />
                      <input type="text" id="digit6" maxLength={1} onInput={(e: any) => moveToNext(e.target, null)} />
                  </form>
                </div>            
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                  <Button disabled={codeIncomplete} type="submit" onClick={verifyConfirmationCode} style={{ padding: "10px 125px", backgroundColor: "#007bff", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>Verify</Button>
                </div>                 
                <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                    <p>
                    Can't find the email? Click <a href="#" onClick={requestAnotherConfirmationCode}>here</a> to resend.
                    </p>
                </div>
             </>          
            :
                <form onSubmit={formik.handleSubmit}>
                <div className="signin-container">
                    <a href="https://zipply.io">
                     <Image src={logo} fluid loading="lazy" style={{ marginBottom: "20px" }}/>
                    </a>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1em" }}>
                    Create an account or&nbsp;<strong><Link to="/signin">Sign In</Link></strong>
                    </div>

                    <input
                    name="firstName" 
                    type="text" 
                    id="firstName" 
                    placeholder="First Name" 
                    autoComplete="given-name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName} />
                    
                    <input
                    name="lastName" 
                    type="text" 
                    id="lastName" 
                    placeholder="Last Name" 
                    autoComplete="family-name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName} />

                    {loading &&
                      <div style={{position: "absolute", paddingLeft: "90px"}}>
                          <Loader size="lg"/>
                      </div>
                    }
                    <input
                    name="email" 
                    type="email" 
                    id="email" 
                    placeholder="Email" 
                    autoComplete="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email} />
                    
                    <div className="password-container">
                      <input
                        name="password" 
                        type={showPassword ? 'text' : 'password'}  
                        id="password" 
                        placeholder="Password" 
                        autoComplete="new-password"
                        onChange={handlePasswordChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password} />
                      <span className="password-toggle-icon"
                          onClick={togglePasswordVisibility} 
                      >
                          {showPassword ? 
                              <ShowPasswordIcon /> 
                            : 
                              <HidePasswordIcon /> 
                          }
                      </span>
                    </div>
                    
                    {renderPasswordCriteria()}
                    <div className="d-grid">
                    <Button type="submit" onClick={() => setAttemptToJoin(true)} disabled={loading}>Join</Button>
                    </div>

                    <label style={{fontSize: "10px"}}>
                      <input
                        name="consent"
                        type="checkbox"
                        onChange={handleConsentChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.consent}
                      />
                      &nbsp;I agree to the&nbsp;
                      <a href="https://zipply.io/terms-of-service" target="_blank">Terms of Service</a>
                      &nbsp;and&nbsp;
                      <a href="https://zipply.io/privacy-policy" target="_blank">Privacy Policy</a>
                    </label>

                    <div style={{ margin: "1em", color: "red", fontSize: "14px" }}>
                      <ul>
                          {message && <li>{message}</li>}
                          {formik.touched.firstName && formik.errors.firstName && <li>{formik.errors.firstName}</li>}
                          {formik.touched.lastName && formik.errors.lastName && <li>{formik.errors.lastName}</li>}
                          {formik.touched.email && formik.errors.email && <li>{formik.errors.email}</li>}
                          {formik.touched.password && formik.errors.password && <li>{formik.errors.password}</li>}
                          {formik.touched.consent && formik.errors.consent && <li>{formik.errors.consent}</li>}
                      </ul>
                    </div>
                </div>
                {process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "TRUE" &&
                  <div style={{ marginTop: "1em", textAlign: "center"}}>
                    <a href={googleAuthorizationUrl} className="google-sign-in-button-image" style={{ boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1"}}/>
                      {/* https://developers.google.com/identity/branding-guidelines
                          https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid */ }
                  </div>
                }
                </form>
            }
          </div>
        </Col>
      </Row>
      <Stack spacing={80} justifyContent="center">
        <Button href='https://zipply.io/terms-of-service' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Terms of Service</Button>
        <Button href='https://zipply.io/privacy-policy' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Privacy Policy</Button>
      </Stack>

      {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY &&
        <Modal className="centered-modal" size="xs" open={showRecaptcha} onClose={() => setShowRecaptcha(false)}>
            <Modal.Header>
            <Modal.Title>Complete the reCAPTCHA</Modal.Title>
            </Modal.Header>
            <Modal.Body className="centered-recaptcha">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                />
            </Modal.Body>
        </Modal>
      }
    </Grid>
    </Modal>
  );
}
