import { Grid, Row, Col, Stack } from 'rsuite';

import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";

import "styles/activity-calendar.css";

interface Props {
    values: any;
    startDate: any;
    endDate: any;
    upperValueBound: any;
  }

export default function ActivityCalendar({ values, startDate, endDate, upperValueBound }: Props) {
    function mapValueToColorClass(value: any) {
        // Ensure value is within the valid range
        const clampedValue = Math.min(Math.max(value.count, 0), upperValueBound);
        
        // Map the clamped value to a scale of 1 to 5
        const scaledValue = Math.ceil((clampedValue / upperValueBound) * 5); // Ensures values are at least 1
    
        // Determine the color class based on the value type
        let colorClass;
        if (value.type === "both") {
            colorClass = `color-scale-both-${scaledValue}`; // Use scaledValue directly
        } else if (value.type === "task") {
            colorClass = `color-scale-task-${scaledValue}`;
        } else if (value.type === "solution") {
            colorClass = `color-scale-solution-${scaledValue}`;
        } else {
            colorClass = "color-empty"; // Default for no type
        }

        return colorClass;
    }

    return (
        <div>
            <Grid fluid>
                <Row>
                        <CalendarHeatmap
                            startDate={startDate}
                            endDate={endDate}
                            values={values}
                            classForValue={(value: any) => {
                                if (!value || value.count === 0) {
                                    return "color-empty";
                                }
                                return mapValueToColorClass(value);
                            }}
                            showWeekdayLabels={true}
                            titleForValue={(value) => {
                                if (!value || value.count === 0) {
                                    if (!value) {
                                        return "No activity";
                                    }
                                    return `No activity on ${value.date}`;
                                }
                                return `${value.date} has ${value.count} ${value.type === "both" ? "tasks and solutions" : value.type}`
                            }}
                        />
                 </Row>
                 <Row>
                 <Legend />
                 </Row>
            </Grid>
        </div>
    );
}

const Legend = () => {
    return (
        <div className="legend">
            <Stack direction="row" spacing={10} alignItems="flex-start">
                <Stack.Item>
                    <div className="legend-item">
                        <div className="legend-square color-scale-task"></div>
                        <span>Tasks&nbsp;Posted</span>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className="legend-item">
                        <div className="legend-square color-scale-solution"></div>
                        <span>Solutions&nbsp;Submitted</span>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className="legend-item">
                        <div className="legend-square color-scale-both"></div>
                        <span>Both</span>
                    </div>
                </Stack.Item>
            </Stack>
        </div>
    );
};