import React, { useEffect, useState, useCallback } from 'react';

import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { Tooltip,  Whisper, useToaster, Panel, Stack, Breadcrumb, Tabs, Loader, Grid, Col, Row, Modal, Button, List, Radio, Table, Tag } from 'rsuite';
import IconReceipt from '@rsuite/icons/DocPass'

import useSWRMutation from 'swr/mutation'
import useSWR from 'swr'
import { fetcher } from "utils/axios";

import { type StripeConnectInstance } from '@stripe/connect-js';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectNotificationBanner,
  ConnectAccountManagement,
  ConnectDocuments,
  ConnectPayments,
  ConnectPayouts,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { getRequest, postRequest } from 'utils/axios';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import TaskDetails from 'components/tasks/TaskDetails';

const stripe_publishable_key: any = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);

type Props = {
  refreshHeader: () => void
};

const Wallet = ({ refreshHeader }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const toaster = useToaster();
  const [managementActiveKey, setManagementActivityKey] = useState<any>("payment-methods");
  const [stripeOptions, setStripeOptions] = useState<any>(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(null);
  const [stripeAccountStatus, setStripeAccountStatus] = useState<string | null>(null);

  useSWR<any>("wallets/payments/history/", fetcher, {
    onSuccess: (data: any) => {
      setPaymentHistory(data);
    }
  })

  const { trigger: refreshPaymentMethods } = useSWRMutation<any>("integrations/stripe/connect/payment-methods/", fetcher, {
    onSuccess: (data: any) => {
      setPaymentMethods(data);
    }
  })

  useEffect(() => {
    if (searchParams.has('success') && searchParams.has('session_id')) {
      if (searchParams.get('success') === 'true') {
          setSearchParams('');
          postRequest("integrations/stripe/connect/payment-methods/add/", {
              session_id: searchParams.get('session_id')
          })
          .then((res) => {
            if (res.status === 201) {
              refreshHeader();
              refreshPaymentMethods();
              toaster.push(<RequestSuccessMessage message="Payment method added successfully" toaster={toaster} />);
            }
          })
          .catch((error) => {
              toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
          }).finally(() => {
            setSearchParams('');
          })
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!stripeConnectInstance) {
      getRequest("integrations/stripe/connect/account-management-components/").then((res) => {
        if (res.status === 200) {
          const connection = loadConnectAndInitialize({
            publishableKey: stripe_publishable_key,
            fetchClientSecret: () => { return res.data.client_secret }
          });
          setStripeConnectInstance(connection);
          setStripeAccountStatus(res.data.account_status);
          refreshPaymentMethods();
        } else {
          throw new Error('Failed to fetch client secret'); 
        }
      });
    }
  }, [stripeConnectInstance]);

  const StripeAccountOnboarding = () => {
    if (stripeConnectInstance === null) {
      return null;
    }
    return (
        <ConnectAccountOnboarding
          onExit={() => {
            setStripeConnectInstance(null);
          }}
        />
    );
  }

  const StripeNotificationBanner = () => {
    if (stripeConnectInstance === null) {
      return null;
    }
    return (
        <ConnectNotificationBanner />
    );
  };

  const StripeAccountManagement = () => {
    if (!stripeConnectInstance) {
      return null;
    }
    return (
        <ConnectAccountManagement />
    );
  };

  const StripeDocuments = () => {
    if (!stripeConnectInstance) {
      return null;
    }
    return (
        <ConnectDocuments />
    );
  };

  const StripePaymentsReceived = () => {
    if (!stripeConnectInstance) {
      return null;
    }
    return (
        <ConnectPayments />
    );
  };

  const StripePayouts = () => {
    if (!stripeConnectInstance) {
      return null;
    }
    return (
        <ConnectPayouts />
    );
  };

  const StripeSavePaymentMethod = () => {
    return (
      <Modal size="xs"
        open={stripeOptions !== null}
        onClose={() => setStripeOptions(null)}>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={stripeOptions}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Modal>
    );
  };

  const PaymentMethodListItems = ({ paymentMethods }: any) => {
    const handleRemovePaymentMethod = (paymentMethodId: any) => {
      postRequest(`/integrations/stripe/connect/payment-methods/delete/`, {
        payment_method_id: paymentMethodId
      }).then((res) => {
        if (res.status === 200) {
          refreshHeader();
          setPaymentMethods((prev) =>
            prev.filter((pm: any) => pm.id !== paymentMethodId)
          );
          setStripeOptions(null);
          refreshPaymentMethods();
        }
      });
    };

    function handleSetDefaultPaymentMethod(paymentMethodId: any) {
      postRequest(`/integrations/stripe/connect/payment-methods/default/`, {
        payment_method_id: paymentMethodId
      }).then((res) => {
        if (res.status === 200) {
          // Set the default payment method
          setPaymentMethods((prev: any) =>
            prev.map((pm: any) => {
              if (pm.id === paymentMethodId) {
                pm.default = true;
              } else {
                pm.default = false;
              }
              return pm;
            })
          );
        }
      });
    }    

    function displayPaymentName(pm: any) {
      if (pm.type === 'card') {
        return (
          <div>
            <strong>Card:</strong> {pm.brand.toUpperCase()} **** {pm.last4}
            <br/>
            <strong>Expires:</strong> {pm.exp_month}/{pm.exp_year}
          </div>)
      } else if (pm.type === 'cashapp') {
        return (<strong>Cash App Pay</strong>)
      } else {
        return (<strong>{pm.type.toUpperCase()}</strong>)
      }
    }

    return paymentMethods.map((pm: any) => (
      <List.Item key={pm.id}>
        <Panel>
          {/* Display card brand, last 4 digits, and expiry */}
          {displayPaymentName(pm)}

          {/* Default payment method selection */}
          <div>
            <Radio
              checked={pm.default} 
              onChange={() => handleSetDefaultPaymentMethod(pm.id)}
            >
              Default Payment Method
            </Radio>
          </div>

          {/* Remove button */}
          <Button
            appearance="link"
            color="red"
            onClick={() => handleRemovePaymentMethod(pm.id)}
          >
            Remove
          </Button>
        </Panel>
      </List.Item>
    ))
  }

  const StripePaymentMethods = () => {
    function handleAddPaymentMethod() {
      getRequest("integrations/stripe/connect/save-payment-method-components/")
      .then((res) => {
        if (res.status === 200) {
          if (!stripeOptions) {
            function fetchClientSecret() {
              return res.data.client_secret
            }
            setStripeOptions({ fetchClientSecret });
          }
        }
      }).catch((error) => {
        toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
      })
    }

    return (<>
      <StripeSavePaymentMethod />
        <List>
          <PaymentMethodListItems paymentMethods={paymentMethods} />
          <List.Item key="add">
            <Button
                  appearance="link"
                  color="blue"
                  onClick={handleAddPaymentMethod}
                >
                  Add Payment Method
                </Button>
          </List.Item>
        </List>
    </>);
  };

  function PaymentHistory() {
    const truncateTitle = (title: any, maxLength: number) => {
      return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
    };    
    
    const data = paymentHistory.map((payment: any) => ({
      id: payment.id,
      date: new Date(payment.created).toLocaleString(),
      amount: (payment.amount_in_cents / 100).toFixed(2),
      status: payment.current_status,
      type: payment.type,
      description: payment.description,
      taskTitle: truncateTitle(payment.task_title, 20), 
      taskId: payment.task_id,
      receiptUrl: payment.receipt_url
    }));

    const getStatusColor = (status: any) => {
      switch (status) {
        case 'SUCCEEDED':
          return 'green';
        case 'FAILED':
          return 'red';
        case 'CANCELED':
          return 'orange';
        default:
          return 'blue';
      }
    };
  
    return (
        <Table
          height={400}  // Adjust the table height based on the number of rows
          data={data}
          style={{ fontSize: "12px", lineHeight: "1.5" }}  // Set font size to 12px and adjust line height
          rowHeight={36}  // You can adjust this based on font size to create enough padding per row
        >
          <Table.Column width={35} align="left" resizable>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.Cell dataKey="receipt_url">
              {rowData => (
                <a href={rowData.receiptUrl} target="_blank" rel="noreferrer"><IconReceipt /></a>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column width={180} align="left" resizable>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.Cell dataKey="date" />
          </Table.Column>
  
          <Table.Column width={100} resizable>
            <Table.HeaderCell align="left">Status</Table.HeaderCell>
            <Table.Cell align="center">
              {rowData => (
                <Tag size="sm" color={getStatusColor(rowData.status)}>{rowData.status}</Tag>
              )}
            </Table.Cell>
          </Table.Column>
  
          <Table.Column width={100} align="left" resizable>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell dataKey="type"/>
          </Table.Column>

          <Table.Column width={120} align="right" resizable>
            <Table.HeaderCell>Amount (USD)</Table.HeaderCell>
            <Table.Cell>
              {rowData => (
                <strong>${rowData.amount}</strong>
              )}
            </Table.Cell>
          </Table.Column>
  
          <Table.Column width={200} align="left" resizable>
            <Table.HeaderCell>Task ID</Table.HeaderCell>
            <Table.Cell dataKey="description"/>
          </Table.Column>

          <Table.Column width={180} align="left" resizable>
            <Table.HeaderCell>Task Title</Table.HeaderCell>
            <Table.Cell>
              {rowData => (
              <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      <TaskDetails taskId={rowData.taskId} task={null} showDetails={false} showSolutionDetails={false} showNetAmount={rowData.customer_role === 'SOLVER'} />
                    </Tooltip>
                  }
              >
                  <p>{rowData.taskTitle}</p>
              </Whisper>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
    );
  }
  
  
  const StripePanel = () => {
    if (!stripeConnectInstance) {
      return <Loader size="md" content="Connecting to Stripe..." />;
    }
    return (
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Panel bordered header={<strong>Payments & Earnings History</strong>}>         
              <PaymentHistory />
            </Panel>
          </Col>
          <Col xs={12}>
            <Panel bordered header={<strong>Funding & Payout Management</strong>}>  
              <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                {stripeAccountStatus !== "ONBOARDING" &&
                  <StripeNotificationBanner />
                }
                <Tabs activeKey={managementActiveKey} onSelect={setManagementActivityKey} id="stripe-tabs">
                  <Tabs.Tab eventKey="payment-methods" title="Funding Payment Methods">
                    {managementActiveKey === "payment-methods" && <StripePaymentMethods />}
                  </Tabs.Tab>
                    {stripeAccountStatus === "ONBOARDING" ? (
                      <Tabs.Tab eventKey="payout-management" title="Payout Management">
                        {managementActiveKey === "payout-management" && <StripeAccountOnboarding />}
                      </Tabs.Tab>
                    ) : (<>
                      <Tabs.Tab eventKey="payout-management" title="Payout Management">
                        {managementActiveKey === "payout-management" && <StripeAccountManagement />}
                      </Tabs.Tab>
                      <Tabs.Tab eventKey="payments-received" title="Payments Received">
                        {managementActiveKey === "payments-received" && <StripePaymentsReceived />}
                      </Tabs.Tab>
                      <Tabs.Tab eventKey="payouts" title="Payment Payouts">
                        {managementActiveKey === "payouts" && <StripePayouts />}
                      </Tabs.Tab>
                      <Tabs.Tab eventKey="documents" title="Documents">
                        {managementActiveKey === "documents" && <StripeDocuments />}
                      </Tabs.Tab>
                    </>)}
                  </Tabs>
                </ConnectComponentsProvider>
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }

  return (
    <Panel>
      <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between'>
        <Stack.Item>
          <Breadcrumb style={{ fontSize: "20px" }}>
            <Breadcrumb.Item href="#" onClick={() => { navigate('/') }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Wallet</Breadcrumb.Item>
          </Breadcrumb>
        </Stack.Item>
      </Stack>
      <StripePanel />
    </Panel>
  );
};

export default Wallet;
