import { useLocation, Navigate } from 'react-router-dom';
import PublicProfile from './PublicProfile';

const CustomRoute = () => {
    const location = useLocation();

    if (location.pathname.startsWith('/@')) {
        const username = location.pathname.split('@')[1]; 
        return <PublicProfile username={username} />;
    } else {
        return <Navigate to="/" />;
    }
};

export default CustomRoute;
