import React, { useRef, useState, useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { patchFileRequest } from "utils/axios";
import { Link } from "react-router-dom";

import { MDBCardImage } from 'mdb-react-ui-kit';
import countryList from 'react-select-country-list';

import { Breadcrumb, Panel, Form, Loader, useToaster, Schema, Stack, Button, Checkbox, Grid, Col, Row, Divider, IconButton, SelectPicker } from 'rsuite';
import PencilIcon from '@rsuite/icons/legacy/Pencil';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import ZipplyEditor from 'components/editor/ZipplyEditor';
import exp from 'constants';


const countries = countryList().getData();
const excludedCountries = ['IR', 'KP', 'SY', 'RU', 'CU', 'VE', 'MM', 'SD', 'BY'];
const filteredCountries = countries.filter(
  (country: any) => !excludedCountries.includes(country.value)
);

type Props = {
  refreshHeader: () => void
};

const Profile = ({ refreshHeader }: Props) => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const location = useLocation();
  const formRef: any = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector((state: RootState) => state.auth.user);
  const { data: profile, mutate } = useSWR<any>(`/users/${user?.id}/`, fetcher);

  const [initialized, setInitialized] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    username: '',
    display_name: '',
    expertise: '',
    first_name: '',
    last_name: '',
    country_iso: '',
    state_iso: '',
    city: '',
    email: '',
    mobile: '',
    email_notifications_enabled: true
  });

  const [fileInfo, setFileInfo] = useState<any>();

  const expertiseEditorRef: any = useRef();
  const setExpertiseEditorRef = (ref: any) => {
    expertiseEditorRef.current = ref;
  }  

  useEffect(() => {
    if (profile && !initialized) {
      setInitialized(true);
      setFormValue({
        username: profile.username,
        display_name: profile.display_name,
        expertise: profile.expertise,
        first_name: profile.first_name,
        last_name: profile.last_name,
        country_iso: profile.country_iso,
        state_iso: profile.state_iso,
        city: profile.city,
        email: profile.email,
        mobile: profile.mobile,
        email_notifications_enabled: profile.email_notifications_enabled
      });

    }
  }, [profile, initialized, location, navigate, searchParams, toaster, mutate]);

  if (!profile)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching profile details..." />
      </div>
    );

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    display_name: StringType().isRequired("This field is required"),
    first_name: StringType().isRequired("This field is required"),
    last_name: StringType().isRequired("This field is required"),
    country_iso: StringType().isRequired("This field is required")
  });

  const handleSubmit = (event: any) => {
    if (formRef.current.check()) {
      const expertise = expertiseEditorRef.current.getContent();
      if (expertise && expertise.length > 2000) {
        toaster.push(<RequestErrorMessage error='Expertise description is too long. Please limit to 1000 characters.' toaster={toaster} />);
        return;
      }
      const profileUpdateRequest = {
        display_name: formValue.display_name,
        expertise: expertise,
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        country_iso: formValue.country_iso,
        state_iso: formValue.state_iso,
        city: formValue.city,
        mobile: formValue.mobile,
        email_notifications_enabled: formValue.email_notifications_enabled,
        ...(fileInfo && { image: fileInfo }) // Conditionally add the image property
      };

      const patchPromise = patchFileRequest(`/users/${profile.id}/`, profileUpdateRequest);
      patchPromise.then((response) => {
        mutate();
        refreshHeader();
        toaster.push(<RequestSuccessMessage message='Profile successfully updated' toaster={toaster} />);
      }).catch((error) => {
        toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
      });
    }
  };

  const handleChange = (record: any) => {
    setFormValue({
      ...formValue,
      username: record.username,
      display_name: record.display_name,
      expertise: record.expertise,
      first_name: record.first_name,
      last_name: record.last_name,
      country_iso: record.country_iso,
      state_iso: record.state_iso,
      city: record.city,
      email: record.email,
      mobile: record.mobile,
      email_notifications_enabled: record.email_notifications_enabled
    });
  };

  const buildImageSource = () => {
    var imageSource = null;
    if (fileInfo) {
      try {
        imageSource = URL.createObjectURL(fileInfo);
      } catch (err) {
        imageSource = profile.image;
      }
    } else {
      imageSource = profile.image;
    }
    return imageSource;
  };

  const formFieldSize = 200;

  const handleImageClick = () => {
    document.getElementById('file-input')?.click();
  };

  return (
    <Panel>
      <Form style={{ width: '100%' }}
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        fluid>

        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between'>
          <Stack.Item>
            <Breadcrumb style={{fontSize: "20px"}}>
              <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </Stack.Item>
          <Stack.Item>
            <Button appearance="primary" type='submit'>Apply Profile Changes</Button>
          </Stack.Item>
        </Stack>
        <Stack spacing={50} direction='row' alignItems='flex-start' justifyContent='space-between'>
          <Stack.Item>
            <Grid fluid>
              <Row style={{ alignContent: "center", position: 'relative' }}>
                <Col>
                  <Stack direction='column'>
                    <Stack.Item>
                      <MDBCardImage loading="lazy" position="top mb-2 rounded-circle shadow-4-strong" style={{ height: '150px', width: '150px', cursor: 'pointer' }} src={buildImageSource()} onClick={handleImageClick} />
                      <IconButton
                        size="lg"
                        circle
                        icon={<PencilIcon style={{ fontSize: '24px', color: 'darkgrey' }} />}
                        appearance="ghost"
                        style={{ border: '2px solid darkgrey', background: "white", position: 'absolute', bottom: '50px', right: '10px', height: '35px', width: '35px', cursor: 'pointer' }}
                        onClick={handleImageClick}
                      />
                      <input type="file" id="file-input" style={{ display: 'none' }} accept="image/png, image/jpeg" onChange={(e) => setFileInfo(e.target.files && e.target.files.length > 0 ? e.target.files[0] : null)} />
                    </Stack.Item>
                    <Stack.Item>
                      Profile Photo<br /><span style={{ fontSize: '12px' }}>(Click to change)</span>
                    </Stack.Item>
                  </Stack>
                </Col>
              </Row>
            </Grid>
          </Stack.Item>
          <Stack.Item grow={1}>
            <Form.Group controlId="expertise">
              <Form.ControlLabel>What am I Good at?</Form.ControlLabel>
                <Form.HelpText>To help taskers evaluate your skills effectively, please provide a detailed description of your expertise. A well-crafted description not only showcases your talents but also enhances your chances of being selected for tasks that require invitations. Let your skills shine!</Form.HelpText>
                <ZipplyEditor key='expertise' content={formValue.expertise} setEditorRef={setExpertiseEditorRef} readOnly={false} height={200} />
              </Form.Group>
          </Stack.Item>
        </Stack>

        <Divider />

        <Stack spacing={30} justifyContent='flex-start' style={{ marginBottom: "10px" }}>
          <Stack.Item>
            <Link to={`/@${formValue.username}`} >My Public Profile</Link>
          </Stack.Item>
          <Stack.Item>
            Member since: {new Date(profile.created).toLocaleDateString()}
          </Stack.Item>
          <Stack.Item>
            {profile.last_signin_time ?
              (<span>Last sign in: {new Date(profile.last_signin_time).toLocaleString()}</span>)
              :
              (<span>Welcome to Zipply. This is the first time you have signed in.</span>)
            }
          </Stack.Item>
        </Stack>

        <h5>Name</h5>
        <Form.Group controlId="name">
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>First Name<sup>*</sup></Form.ControlLabel>
              <Form.Control name="first_name" />
            </Stack.Item>
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>Last Name<sup>*</sup></Form.ControlLabel>
              <Form.Control name="last_name" />
            </Stack.Item>
          </Stack>
        </Form.Group>

        <Form.Group controlId="username">
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>Username<sup>*</sup></Form.ControlLabel>
              <Form.Control name="username" readOnly />
            </Stack.Item>
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>Display Name<sup>*</sup></Form.ControlLabel>
              <Form.Control name="display_name" />
            </Stack.Item>
          </Stack>
        </Form.Group>

        <Form.Group controlId="location">
          <h5>Your Location</h5>
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
            <Stack.Item basis={(formFieldSize / 3).toString()}>
              <Form.ControlLabel>Country<sup>*</sup></Form.ControlLabel>
                <Form.Control 
                    name="country_iso"
                    accepter={SelectPicker}
                    style={{ 
                        width: '100%', 
                        border: '1px solid #ccc',  // Add border here
                        borderRadius: '4px'        // Optional: for rounded corners
                    }}
                    placeholder="Select your country of residence"
                    data={filteredCountries}
                    value={formValue.country_iso}
                    onChange={(value: any) => setFormValue({ ...formValue, country_iso: value })}
                    block
                />
            </Stack.Item>
            <Stack.Item basis={(formFieldSize / 3).toString()}>
              <Form.ControlLabel>State</Form.ControlLabel>
              <Form.Control name="state_iso" />
            </Stack.Item>            
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>City</Form.ControlLabel>
              <Form.Control name="city" />
            </Stack.Item>
          </Stack>
        </Form.Group>

        <Form.Group controlId="contact">
          <h5>Contact</h5>
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
            <Stack.Item grow={1} basis={formFieldSize.toString()}>
              <Form.ControlLabel>Email<sup>*</sup></Form.ControlLabel>
              <Form.Control name="email" readOnly />
            </Stack.Item>
            <Stack.Item basis={formFieldSize.toString()}>
              <Form.ControlLabel>Mobile</Form.ControlLabel>
              <Form.Control name="mobile" />
            </Stack.Item>
          </Stack>
        </Form.Group>

        <Form.Group controlId="notifications">
        <h5>Notification Options</h5>
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='flex-start'>
            <Stack.Item>
              <Form.Control 
                name="email_notifications_enabled" 
                accepter={Checkbox} 
                checked={formValue.email_notifications_enabled}
                onChange={(value: any) => setFormValue({ ...formValue, email_notifications_enabled: !formValue.email_notifications_enabled })}
                style={{marginTop: "-6px"}}
              />
            </Stack.Item>
            <Stack.Item>
            <Form.ControlLabel>
                Receive email notifications for:
                <ul>
                    <li>New solutions submitted for your tasks</li>
                    <li>When taskers accept your solutions</li>
                    <li>New tasks posted by your favorite taskers, through the follower feature</li>
                </ul>
            </Form.ControlLabel>
            </Stack.Item>
          </Stack>
        </Form.Group>

        {profile?.is_staff && (<>
          <h5>Permissions</h5>
          <Checkbox defaultChecked={profile?.is_superuser} readOnly>Superuser</Checkbox>
          <Form.HelpText>
            Designates that this user has all permissions without explicitly assigning them. This can only be configured by Zipply IT.
          </Form.HelpText>
        </>)}
        <Stack spacing={10} direction='column' alignItems='flex-end' justifyContent='space-between'>
            <Button appearance="primary" type='submit'>Apply Profile Changes</Button>
          </Stack>
      </Form>
    </Panel>
  );
};

export default Profile;
