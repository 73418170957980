import { Stack } from 'rsuite';

export const SolutionDates = ( { taskPostedDate, solution, responsive, short }: any ) => {
    const posted_at = new Date(taskPostedDate).toLocaleDateString()
    let invited_at = 'Pending'
    let submitted_at = 'No'
    let accepted_at = 'No'

    if (solution.invited_date)
      invited_at = new Date(solution.invited_date).toLocaleDateString()
    if (solution.submitted_date)
      submitted_at = new Date(solution.submitted_date).toLocaleDateString()
    if (solution.accepted_date)
      accepted_at = new Date(solution.accepted_date).toLocaleDateString()

    if (responsive)
      return (
        <Stack direction="column" justifyContent='flex-start' alignItems='flex-start'>
          <Stack.Item>Posted: {posted_at}</Stack.Item>
          <Stack.Item>Invited: {invited_at}</Stack.Item>
          <Stack.Item>Submitted: {submitted_at}</Stack.Item>
          {!short && <Stack.Item>Accepted: {accepted_at}</Stack.Item>}
        </Stack>
      );        
    else 
      if (short)
        return (
          <span>Posted: {posted_at} | Invited: {invited_at} | Submitted: {submitted_at}</span>
        );
      else
        return (
          <span>Posted: {posted_at} | Invited: {invited_at} | Submitted: {submitted_at} | Accepted: {accepted_at}</span>
      );
  };

  export default SolutionDates;