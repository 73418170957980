import { Panel } from 'rsuite';

import GenericBadge from "assets/images/generic-badge.png"

interface Props {
    badge: any
}

const AwardedBadge = ({ badge }: Props) => {
    return (
        <Panel bordered style={{ width: '220px', margin: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px' }}>
                <img src={badge.image_url || GenericBadge} style={{ width: '150px', height: '150px' }} />
                <p style={{ textAlign: 'center', margin: '10px 0 5px', fontSize: "16px" }}>{badge.name}</p>
                <p style={{ textAlign: 'left', margin: '0 0 10px', fontSize: "10px" }}>{badge.description}</p>
            </div>
            <footer style={{ textAlign: 'center', fontSize: '10px', color: '#888' }}>
                Awarded on: {new Date(badge.created).toDateString()}
            </footer>
        </Panel>
    );
};

export default AwardedBadge;
