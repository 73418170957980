import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getRequest, postRequest } from "utils/axios";
import zxcvbn from "zxcvbn";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Button, useToaster, Grid, Col, Row, Stack } from 'rsuite';
import Image from 'react-bootstrap/Image';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';

import logo from "assets/images/zipply-logo-text-200x100.png"
//const logo = "https://via.placeholder.com/300x50.png?text=Logo";
import splash from "assets/images/zipply-splash.png"
//const splash = "https://via.placeholder.com/512x512.png?text=Splash";

interface Props {
  open: boolean
  handleClose: () => void
}

export default function ResetPasswordModal({ open, handleClose }: Props) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [performVerification, setPerformVerification] = useState(false);
  const [codeIncomplete, setCodeIncomplete] = useState(true);
  const [attemptToJoin, setAttemptToJoin] = useState(false);
  const toaster = useToaster(); 
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    specialChar: false,
    number: false,
    strength: false,
  });

  const handleCodeVerification = (recaptcha: any, email: any, password: any) => {
    postRequest('/auth/reset/', {
        email,
        password,
        recaptcha
    })
    .then((res) => {
      setVerifyCode(true);
      if (res.status === 201) {
        setPerformVerification(true);
      }
    })
    .catch((err) => {
      toaster.push(<RequestErrorMessage error="An error occurred. Please try again." toaster={toaster}/>);
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address.").required("Email is required."),
    
    password: Yup.string().trim()
      .required("Password is required.")
      .test("is-valid-password", "Password must meet all criteria.", (value) => {
        return Object.values(passwordCriteria).every(Boolean)
      }),
    
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required.')
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    onSubmit: (values) => {
      setShowRecaptcha(true);
    },
    validationSchema: validationSchema,
  });

  function requestAnotherConfirmationCode() {
    if (performVerification) {
      const email = formik.values.email

      postRequest('/auth/generate/', { email, type: 'reset'})
      .then((res) => {
        toaster.push( <RequestSuccessMessage message={'A new code has been sent.'} toaster={toaster}/> )
      })
      .finally(() => {
        clearCodeInput();
      });
    } else {
      toaster.push( <RequestSuccessMessage message={'A new code has been sent.'} toaster={toaster}/> )
      clearCodeInput();
    }
  }  

  function verifyConfirmationCode() {
    if (performVerification) {
      const username = formik.values.email
      const password = formik.values.password

      const digits = [];
      for (let i = 1; i <= 6; i++) {
        const element = document.getElementById(`digit${i}`) as HTMLInputElement | null;
        if (element) {
          digits.push(element.value);
        }
      }
      const verification_code = digits.join('');

      postRequest('/auth/login/', { username, password, verification_code, type: 'reset' })
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setUser(res.data.user));
        const configurationPromise = getRequest('/system/configurations/ui/')
        configurationPromise.then((response: any) => {
          dispatch(configurationSlice.actions.setConfiguration(response.data));
        })   
        navigate("/", {
          state: {
            userId: res.data.id
          }
        });
      })
      .catch((err) => {
        toaster.push( <RequestErrorMessage error="The verification code you've entered is incorrect." toaster={toaster}/> )
      })
      .finally(() => {
        clearCodeInput();
      });
    } else {
      toaster.push( <RequestErrorMessage error="The verification code you've entered is incorrect." toaster={toaster}/> )
      clearCodeInput();
    }
  }

  const handleRecaptchaChange = (value: any) => {
    if (value) {
      handleCodeVerification(value, formik.values.email, formik.values.password);
      setShowRecaptcha(false);
    } else {
      toaster.push(<RequestErrorMessage error="Please complete the reCAPTCHA." toaster={toaster}/>);
    }
  };

  const moveToNext = (current: any, nextFieldID: any) => {
    if (current.value.length >= current.maxLength) {
      if (nextFieldID) {
        const nextField = document.getElementById(nextFieldID);
        if (nextField) {
          nextField.focus();
        }
      }
    }
    areAllDigitsEntered();
  };

  const areAllDigitsEntered = () => {
    setCodeIncomplete(false);
    const verificationForm = document.getElementById('verificationForm');
    if (verificationForm) {
        const inputs = verificationForm.querySelectorAll('input');
        if (inputs.length !== 6) {
          setCodeIncomplete(true);
        }
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value.trim() === '' || inputs[i].value.length !== 1) {
              setCodeIncomplete(true);
            }
        }
    } else {
      setCodeIncomplete(true);
    }
  };

  const clearCodeInput = () => {
    setCodeIncomplete(true);
    const verificationForm = document.getElementById('verificationForm');

    if (verificationForm) {
      const inputs = verificationForm.querySelectorAll('input');
      inputs.forEach((input: any) => {
          input.value = '';
      });
    }
  };

  const enableToPasteCode = () => {
    const verificationForm = document.getElementById('verificationForm');

    if (verificationForm) {
      const handlePaste = (e: ClipboardEvent) => {
        const paste = e.clipboardData?.getData('text');
        if (paste && /^\d{6}$/.test(paste)) {
          const inputs = verificationForm.querySelectorAll('input');
          inputs.forEach((input: any, index: any) => {
            input.value = paste[index];
          });
          inputs[inputs.length - 1].focus();
        }
        e.preventDefault();
        areAllDigitsEntered();
      };

      if (verificationForm) {
        verificationForm.addEventListener('paste', handlePaste);
      }

      return () => {
        if (verificationForm) {
          verificationForm.removeEventListener('paste', handlePaste);
        }
      };
    }
  };

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    formik.setFieldValue("password", password);
    const result = zxcvbn(password);

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /[0-9]/;

    // This must be in sync with backend password validation
    const criteria = {
      length: password.length >= 8,
      specialChar: specialCharRegex.test(password),
      number: numericRegex.test(password),
      strength: true // TODO result.score >= 3,
    };

    setPasswordCriteria(criteria);
  };

  const renderPasswordCriteria = () => {
    if (!attemptToJoin) {
      return null 
    }
    const criteria = [
      { label: "At least 8 characters", met: passwordCriteria.length },
      { label: "At least one special character", met: passwordCriteria.specialChar },
      { label: "At least one numeric character", met: passwordCriteria.number },
      /* TODO { label: "Strong password", met: passwordCriteria.strength }, */
    ];

    return (
      <ul style={{ marginLeft: "-10px", listStyleType: "none", fontSize: "14px" }}>
        {criteria.map((criterion, index) => (
          <li key={index} style={{ color: criterion.met ? "green" : "red" }}>
            {criterion.met ? "✔️" : "❌"} {criterion.label}
          </li>
        ))}
      </ul>
    );
  };  

  return (
    <Modal size="lg"
    open={open}
    onClose={handleClose}>
<Grid fluid>
  <Row>
    <Col xsHidden={true} smHidden={true} mdHidden={true} lg={16} xl={16} xxl={16}>
      <div className="center-container">
      <img src={splash} loading="lazy" style={{ width: "490px", height: "490px", borderRadius: "20px", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }} />
      </div>
    </Col>
    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} >
      <div className="center-container">
          {verifyCode ? (
            <>
              <a href="https://zipply.io">
                <Image src={logo} fluid loading="lazy" style={{ marginBottom: "20px" }}/>
              </a>
              <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                <p>
                  Please enter the verification code sent to your email address <strong>{formik.values.email}</strong>
                </p>
              </div>
              <div className="verification-container" style={{ textAlign: "center" }}>
                <form id="verificationForm" onSelect={enableToPasteCode}>
                  <input type="text" id="digit1" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit2')} />
                  <input type="text" id="digit2" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit3')} />
                  <input type="text" id="digit3" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit4')} />
                  <input type="text" id="digit4" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit5')} />
                  <input type="text" id="digit5" maxLength={1} onInput={(e: any) => moveToNext(e.target, 'digit6')} />
                  <input type="text" id="digit6" maxLength={1} onInput={(e: any) => moveToNext(e.target, null)} />
                </form>
              </div>            
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                <Button disabled={codeIncomplete} type="submit" onClick={verifyConfirmationCode} style={{ padding: "10px 125px", backgroundColor: "#007bff", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>Verify</Button>
              </div>                 
              <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                <p>
                  Can't find the email? Click <a href="#" onClick={requestAnotherConfirmationCode}>here</a> to resend.
                </p>
              </div>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="signin-container">
                <a href="https://zipply.io">
                  <Image src={logo} fluid loading="lazy" style={{ marginBottom: "20px" }}/>
                </a>
                <input
                  name="email"
                  type="email"
                  id="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
                <input
                  name="password"
                  type="password"
                  id="password"
                  placeholder="New Password"
                  autoComplete="new-password"
                  onChange={handlePasswordChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                <input
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />

                {renderPasswordCriteria()}

                <div className="d-grid">
                  <Button type="submit" onClick={() => setAttemptToJoin(true)} >Reset Password</Button>
                </div>

                <div style={{ margin: "1em", color: "red", fontSize: "14px" }}>
                  <ul>
                  {formik.touched.email && formik.errors.email && <li>{formik.errors.email}</li>}
                  {formik.errors.password && formik.touched.password && <li>{formik.errors.password}</li>}
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && <li>{formik.errors.confirmPassword}</li>}
                  </ul>
                </div>

                <div style={{ fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                 <strong><Link to="/signin">Sign&nbsp;In</Link></strong>
                </div>
              </div>
            </form>
          )}
          {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY &&
            <Modal className="centered-modal" size="xs" open={showRecaptcha} onClose={() => setShowRecaptcha(false)}>
              <Modal.Header>
                <Modal.Title>Complete the reCAPTCHA</Modal.Title>
              </Modal.Header>
              <Modal.Body className="centered-recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </Modal.Body>
            </Modal>
          }
        </div>
      </Col>
    </Row>
    <Stack spacing={80} justifyContent="center">
        <Button href='https://zipply.io/terms-of-service' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Terms of Service</Button>
        <Button href='https://zipply.io/privacy-policy' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Privacy Policy</Button>
    </Stack>
    </Grid>
    </Modal> 
  );
}
