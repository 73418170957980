import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from 'store';
import authSlice from 'store/slices/auth';
import { getCookie } from 'utils/utils'


const jsonRootAxiosService = axios.create({
    baseURL: process.env.REACT_APP_ROOT_URL,
    headers: {
        'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRFTOKEN': getCookie('csrftoken')
    },
});

const jsonApiAxiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRFTOKEN': getCookie('csrftoken')
    },
});

const multipartApiAxiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
});

jsonApiAxiosService.interceptors.request.use(async (config) => { return authenticationInterceptorRequest(config) });
jsonApiAxiosService.interceptors.response.use(
    (res) => { return refreshInterceptorResponse(res) },
    (err) => { return refreshInterceptorError(err) }
);

multipartApiAxiosService.interceptors.request.use(async (config) => { return authenticationInterceptorRequest(config) });
multipartApiAxiosService.interceptors.response.use(
    (res) => { return refreshInterceptorResponse(res) },
    (err) => { return refreshInterceptorError(err) }
);

function authenticationInterceptorRequest(config: any) {
    const { token } = store.getState().auth;

    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
        // @ts-ignore
        console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    } else {
        config.headers.Authorization = null;
    }

    return config;
}

function refreshInterceptorResponse(res: any) {
    // @ts-ignore
    console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
    return Promise.resolve(res);
}

function refreshInterceptorError(err: any) {
    if (err.response && err.response.status === 401) {
        refreshAccessToken();
    }
    console.debug(
        '[Response]',
        err.config.baseURL + err.config.url,
        err.response.status,
        err.response.data
    );
    return Promise.reject(err);
}    

function refreshAccessToken() {
    const { refreshToken } = store.getState().auth;
    if (refreshToken !== null) {
        return axios
            .post(`${process.env.REACT_APP_API_URL}/auth/refresh/`,
                {
                    refresh: refreshToken
                }
            )
            .then((resp) => {
                const access = resp.data.access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({ token: access, refreshToken: refreshToken })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(authSlice.actions.setLogout());
                }
            });
    }
};

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const { refreshToken } = store.getState().auth;
    if (refreshToken !== null) {
        return axios
            .post(`${process.env.REACT_APP_API_URL}/auth/refresh/`,
                {
                    refresh: refreshToken
                }
            )
            .then((resp) => {
                const access = resp.data.access;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({ token: access, refreshToken: refreshToken })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(authSlice.actions.setLogout());
                }
            });
    }
};

createAuthRefreshInterceptor(jsonApiAxiosService, refreshAuthLogic);

export function fetcher<T = any>(url: string) {
    return jsonApiAxiosService.get<T>(url).then((res) => res.data);
}

export function getRequest<T = any>(url: string) {
    return jsonApiAxiosService.get<T>(url).then(response => response);
}

export function postRootRequest(url: string, payload?: any) {
    return jsonRootAxiosService.post(url, payload).then(response => response);
}

export function postRequest(url: string, payload?: any) {
    return jsonApiAxiosService.post(url, payload).then(response => response);
}

export function postFileRequest(url: string, payload: any, onUploadProgress?: (progressEvent: any) => void, headers?: any) {
    let axiosService = multipartApiAxiosService;
    if (headers) {
        axiosService = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: headers
        });
    }    
    return axiosService.post(url, payload, {
        onUploadProgress
    }).then(response => response);
}

export function putFileRequest(url: string, payload: any, onUploadProgress?: (progressEvent: any) => void, headers?: any) {
    let axiosService = multipartApiAxiosService;
    if (headers) {
        axiosService = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: headers
        });
    }
    return axiosService.put(url, payload, {
        onUploadProgress
    }).then(response => response);
}

export function putRequest(url: string, payload?: any) {
    return jsonApiAxiosService.put(url, payload).then(response => response);
}

export function patchRequest(url: string, payload: any) {
    return jsonApiAxiosService.patch(url, payload).then(response => response);
}

export function patchFileRequest(url: string, payload: any) {
    return multipartApiAxiosService.patch(url, payload).then(response => response);
}

export function deleteRequest(url: string) {
    return jsonApiAxiosService.delete(url).then(response => response);
}