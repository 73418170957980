import React, { useState, useEffect } from 'react';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Link } from "react-router-dom";
import { Grid, Row, Panel, Loader, List, Stack, Tag, Whisper, Tooltip, Badge } from 'rsuite';

import { FaExclamationCircle } from 'react-icons/fa'

import { useSelector } from "react-redux";
import {RootState} from "store";

import SolverSolutionModal from 'components/solutions/SolverSolutionModal';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars, mapSolutionStatus } from 'utils/utils';
import SolutionDates from 'components/elements/SolutionDates';

const MySolutions = () => {
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
  const [solverSolutionModalOpen, setSolverSolutionModalOpen] = useState(false);

  const header: any = useSelector((state: RootState) => state.header);
  const { data: tasksSolving, trigger: refreshTasksSolving } = useSWRMutation<any>(`/tasks/solving/?q=${header.searchQuery}`, fetcher);

  useEffect(() => {
    refreshTasksSolving()
  }, [refreshTasksSolving, header.searchQuery]);


  function handleSolverSubmitSolution(taskId: any) {
    setSelectedTaskId(taskId)
    setSolverSolutionModalOpen(true)
  }

  function handleSolverSolutionClose() {
    setSolverSolutionModalOpen(false)
    refreshTasksSolving()
  }

  const SolutionsTaskListItem = ({ task, onClick }: { task: any; onClick: () => void }) => {
    const { title, my_solution, current_status, posted_date, reward_in_cents, zipply_fee_in_cents, due_date } = task;
  
    const displayStatus: any = mapSolutionStatus(my_solution.current_status)

    return (
      <List.Item onClick={onClick} style={{ cursor: 'pointer' }}>
        <Row><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item style={{fontSize: "12px"}}>
              <SolutionDates taskPostedDate={posted_date} solution={task.my_solution} responsive={false} short={false}/></Stack.Item>
            <Stack.Item style={{fontSize: "14px"}}>{task.tasker.display_name}</Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item>
                  {my_solution.can_provide_feedback ? 
                    <Whisper onClick={onClick} placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Please provide timely feedback to tasker!</Tooltip>}>
                      <Badge content={<FaExclamationCircle style={{ color: 'white', fontSize: '10px', marginTop: "-2px" }}/>}>
                        <Tag color={displayStatus.color} style={{width: "100px", textAlign: "center"}}>{displayStatus.text}</Tag>
                      </Badge>
                    </Whisper>
                    :
                    <Tag onClick={onClick} color={displayStatus.color} style={{width: "100px", textAlign: "center"}}>{displayStatus.text}</Tag>
                  }
                </Stack.Item>
                <Stack.Item style={{width: "80px"}}>
                  {current_status === 'SOLVED' ?
                    <small>Solved</small>
                    :
                    <>{current_status === 'CANCELED' ?
                      <small>Canceled</small>
                      :
                      <small><TimeRemaining endDate={new Date(due_date)}/></small>
                    }</>
                  }
                </Stack.Item>
                <Stack.Item><Tag style={{width: "40px", textAlign: "right"}} color="blue">{displayCentAmountInDollars(reward_in_cents - zipply_fee_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
    </List.Item>
    );
  };      

  return (
    <div>
      <Grid fluid>
        <Row style={{ margin: '20px' }}>
          <Panel header={<Link to="/my-solutions" className="nonStyledLink">Tasks you're Solving</Link>}>
            {tasksSolving && tasksSolving.count === 0 && <p>No tasks available</p>}
            {tasksSolving && tasksSolving.count > 0 && <>
              <ul>
                {tasksSolving.results.map((task: any, index: number) => (
                  <SolutionsTaskListItem task={task} onClick={() => handleSolverSubmitSolution(task.id)}/>
                ))
                }
              </ul>
              <SolverSolutionModal taskId={selectedTaskId} open={solverSolutionModalOpen} close={handleSolverSolutionClose} />
            </>}
            {!tasksSolving && <Loader size="md" content="Fetching tasks..." />}
          </Panel>
        </Row>
      </Grid>
    </div>
  );
};

export default MySolutions;
