import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Grid, Row } from 'rsuite';

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';

interface Props {
    signIn?: boolean
    signUp?: boolean
    resetPassword?: boolean
  }
  
export default function TOS({ signIn, signUp, resetPassword }: Props) {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
<div>
    <PublicHeader 
        showSearch={false}
        signIn={signIn} 
        signUp={signUp} 
        resetPassword={resetPassword} 
    />
    <Grid fluid>
        <Row style={{ margin: '20px' }}>
        <h1>Terms of Service</h1>
<p>Last Updated: November 11, 2024</p>

<p>Welcome to Zipply</p>

<p>These <strong>Terms of Service</strong> outline the rules and regulations for the use of Zipply's website and web app, located at <a href="https://zipply.io">https://zipply.io</a>.</p>

<p>Please take a moment to carefully review the <strong>Terms of Service</strong> before using Zipply. By accessing the Site, creating an account, or by selecting the option to accept or agree to the <strong>Terms of Service</strong> when prompted, you confirm your acceptance and agreement, either personally or on behalf of your employer or another entity (if applicable), to comply with these <strong>Terms of Service</strong>, the <strong>Zipply Payment Terms</strong>, available <Link to="/payment-terms-of-service">here</Link> (“Payment Terms”), and any other applicable standards, conditions, policies, and guidelines, all of which are incorporated into these Terms directly or by reference. Additionally, you acknowledge that you have reviewed and understood our <strong>Privacy Policy</strong>, available <Link to="/privacy-policy">here</Link>. If you do not agree to these Terms or the Privacy Policy, you must refrain from accessing or using the Site. For further details regarding our policies on site activity and usage, please refer to the appropriate sections provided.</p>

<p><strong>Cookies:</strong> The website and web app use cookies to help personalize your online experience. By accessing Zipply, you agree to use the required cookies.</p>

<p>A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>

<p>We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website and web app. You have the ability to accept or decline optional cookies. There are some required cookies that are necessary for the operation of our website and web app. These cookies do not require your consent as they always work. Please keep in mind that by accepting required cookies, you also accept third-party cookies, which might be used via third-party provided services if you use such services on our website.</p>

<p><strong>License:</strong> Unless otherwise stated, Zipply and/or its licensors own the intellectual property rights for all material on Zipply. All intellectual property rights are reserved. You may access this from Zipply for your own personal use, subject to restrictions set in these <strong>Terms of Service</strong>.</p>

<p><strong>You must not:</strong></p>

<ul>
    <li>Copy or republish material from Zipply</li>
    <li>Sell, rent, or sub-license material from Zipply</li>
    <li>Reproduce, duplicate, or copy material from Zipply</li>
    <li>Redistribute content from Zipply</li>
</ul>

<p>This Agreement shall begin on the date hereof.</p>

<h2>Representations and Warranties</h2>

<p>This Site is offered and available to users who are at least 18 years of age and of legal age to form a binding contract. If you are under 18 and at least 13 years of age, you are only permitted to use the Site through an account owned by a parent or legal guardian with their appropriate permission. If you are under 13 you are not permitted to use the Site or the Zipply services.</p>

<p>The Site is available only to users who are not subject to any economic sanctions or trade restrictions imposed by the United States, European Union, or any other applicable jurisdiction.</p>

<p>By using the Site, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Site.</p>

<h2>Key Terms</h2>

<p><strong>Taskers</strong> are users who post tasks and offer rewards for solutions.</p>
<p><strong>Solvers</strong> are users who complete tasks and submit solutions in exchange for rewards.</p>
<p><strong>Rewards</strong> mean the monetary compensation offered by taskers for completed tasks.</p>
<p><strong>Fees</strong> are charges associated with the services provided on Zipply.</p>

<section>
    <h2>Service Fees and Payment Structure</h2>
    <p>As part of your use of Zipply, you acknowledge and agree to the platform's payment structure, including service fees, payment authorizations, and deferred payment terms. For more detailed information regarding the full Payment Terms of Service, please refer to our <strong>Payment Terms</strong>, found <Link to="/payment-terms-of-service">here</Link> (“Payment Terms”). This document outlines important aspects such as payment processing, payout requirements, transaction limits, refunds, and any other relevant details governing the financial interactions on Zipply.</p>
</section>

<h2>User-Generated Content</h2>

<p>Parts of this website and web app offer users an opportunity to post and exchange opinions and information in certain areas of the website. Zipply does not filter, edit, publish or review comments before their presence on the website. Comments do not reflect the views and opinions of Zipply, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Zipply shall not be liable for the comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the comments on this website.</p>

<p>Zipply reserves the right to monitor all comments and remove any comments that can be considered inappropriate, offensive, or causes breach of these <strong>Terms of Service</strong>.</p>

<p><strong>You warrant and represent that:</strong></p>

<ul>
    <li>You are entitled to post the comments on our website and have all necessary licenses and consents to do so;</li>
    <li>The comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
    <li>The comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy;</li>
    <li>The comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
</ul>

<p>You hereby grant Zipply a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your comments in any and all forms, formats, or media.</p>

<h2>Content Uploads</h2>

<p><strong>Content Uploads:</strong> Users may upload content while using the Zipply platform. You retain ownership of the content you upload, but you grant Zipply a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and display that content as necessary for the provision of our services.</p>

<p><strong>Retention and Use of Content:</strong> Zipply reserves the right to retain uploaded content for as long as necessary to provide services and comply with legal obligations.</p>

<p><strong>User Responsibilities:</strong> You agree not to upload any content that is illegal, fraudulent, harmful, or that violates the rights of any third party. Any violation of these terms may result in the suspension or termination of your account and may lead to legal action.</p>

<p><strong>Content Moderation</strong> During onboarding, users must choose a username and display name, both of which are subject to automated profanity checks. Additionally, task and solution content, including titles, descriptions, and uploaded files, will undergo the same checks. Content that violates our standards may be flagged, edited, or removed. Repeated violations may result in account suspension or termination.</p>

<h2>Hyperlinking to our Content</h2>

<p>The following organizations may link to our website without prior written approval:</p>

<ul>
    <li>Government agencies;</li>
    <li>Search engines;</li>
    <li>News organizations;</li>
    <li>Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses;</li>
    <li>System-wide accredited businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
</ul>

<p>These organizations may link to our home page, to publications, or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

<p>We may consider and approve other link requests from the following types of organizations:</p>

<ul>
    <li>Commonly-known consumer and/or business information sources;</li>
    <li>Dot.com community sites;</li>
    <li>Associations or other groups representing charities;</li>
    <li>Online directory distributors;</li>
    <li>Internet portals;</li>
    <li>Accounting, law, and consulting firms; and</li>
    <li>Educational institutions and trade associations.</li>
</ul>

<p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Zipply; and (d) the link is in the context of general resource information.</p>

<p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b)</p>

<section>
<h2>Referral Program Terms</h2>

<p><strong>Eligibility: </strong>
<ul>
<li>To participate in Zipply’s Referral Program, you must be a registered user of Zipply.</li>
<li>Referrals must be made using the in-app email invitation system by entering your friend’s email address. Referrals made outside of the platform will not be eligible for rewards.</li>
</ul>
</p>

<p><strong>Referral Reward: </strong>
<ul>
<li>When you refer a new user to Zipply using the in-app email invitation, and they successfully complete their first task as either a tasker or solver, you will earn 10% of their reward from the first task they solve or post.</li>
<li>The referral reward is credited as a deferred credit to your Zipply account once the referred user successfully completes their first task. These earnings are deferred for 30 days after the task is completed and will be available on the 28th of each month.</li>
</ul>
</p>

<p><strong>Referral Reward Conditions: </strong>
<ul>
    <li>The referred user must not have previously registered with Zipply.</li>
    <li>The referred user must complete their first task either as a tasker or solver for the referral to be valid.</li>
    <li>Referral rewards are credited as deferred credits once the referred user completes their first task. These credits will be deferred for 30 days and will be available for use on the 28th of the following month.</li>
    <li>A verified payout account through Stripe Connect is required to access the funds if you choose to withdraw your deferred credit.</li>
    <li>Referral rewards will be based on the exact email address provided in the referral invite. The referred user must sign up using the same email address as sent in the invitation. Variations of the email address, including those with different dividers or extensions (e.g., `user+alias@gmail.com`), will not be eligible for referral rewards.</li>
</ul>
</p>

<p><strong>Referral Program Abuse: </strong>
<ul>
    <li>Any attempt to artificially generate referrals, including self-referrals or using deceptive methods to encourage users to sign up, will result in disqualification from the program.</li>
    <li>Zipply reserves the right to suspend or terminate the account of any user suspected of engaging in referral program abuse.</li>
    <li>Using variations of the email address that differ from the one sent in the referral invitation to exploit the referral program will result in disqualification from referral rewards.</li>
</ul>
</p>

<p><strong>Reward Payment: </strong>
<ul>
<li>Referral rewards will be credited as deferred credits in your Zipply account.</li>
<li>These deferred credits cannot be exchanged for cash or withdrawn until after the 30-day deferral period, on the 28th of the following month.</li>
<li>To withdraw your deferred credit, you must have a verified payout account via Stripe Connect.</li>
</ul>
</p>

<p><strong>Changes to the Referral Program: </strong>
<ul>
<li>Zipply reserves the right to modify, suspend, or terminate the Referral Program at any time, with or without notice. This includes changes to the reward structure or eligibility criteria.</li>
<li>Any changes to the program will be communicated via email or within the Zipply platform.</li>
</ul>
</p>

<p><strong>Tax Responsibility: </strong>
<ul>
<li>Referral rewards, once credited to your account, may be subject to applicable local taxes. It is your responsibility to report and pay any taxes associated with the rewards received from the Referral Program.</li>
</ul>
</p>

<p><strong>Termination of Account: </strong>
<ul>
<li>If your account is terminated for any reason, you will forfeit any unpaid or unclaimed referral rewards.</li>
<li>Zipply reserves the right to suspend or terminate accounts involved in referral program violations or suspicious activity.</li>
</ul>
</p>
</section>

<section>
<h2>Account Deletion Requests</h2>

<ol>
    <li><strong>Right to Request Deletion: </strong>
    Users have the right to request the deletion of their Zipply account at any time.</li>

    <li><strong>Deletion Process: </strong>
    To initiate an account deletion request, users must send an email from the associated email address on their account to support@zipply.io with the subject line "Account Deletion Request." The email should include the user's account details for verification purposes.</li>

    <li><strong>Payment Requirements: </strong>
    An account cannot be deleted until all pending solver payments have been processed. Currently, payouts are made through StripeConnect. Users will be notified if any payments are pending before their account deletion request can be fulfilled.</li>

    <li><strong>Effects of Account Deletion: </strong>
    Upon successful deletion of an account, users will lose access to all account data, including tasks and solutions. Deleted accounts cannot be restored. Users wishing to use Zipply in the future will need to create a new account.</li>

    <li><strong>Handling of Public Profiles and Handles: </strong>
    Upon account deletion, all associated public profiles and handles will be permanently removed from the Zipply platform. Users will receive a confirmation email detailing the deletion of their account, public profile, and handle. To provide users with the opportunity to change their minds, public profiles will be temporarily suspended for a period of 30 days before complete deletion. During this time, handles will be reserved and will not be available for new users.</li>

    <li><strong>6. Data Retention: </strong>
    Zipply may retain certain information in accordance with its Privacy Policy for record-keeping purposes, but users can request deletion of their data as specified therein.</li>
</ol>
</section>

        </Row>
    </Grid>
    <PublicFooter />
</div>
  );
};
