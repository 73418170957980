import { useEffect, useState } from 'react';
import BundledEditor from 'BundledEditor.js';


interface Props {
    key: any;
    content: any;
    setEditorRef?: (editor: any) => void;
    readOnly: boolean;
    height: number;
  }

export default function ZipplyEditor({ key, content, setEditorRef, readOnly, height }: Props) {
    const [internalKey, setInternalKey] = useState(key);
    const [internalReadOnly, setInternalReadOnly] = useState(readOnly);
    const [internalContent, setInternalContent] = useState(content);
    const [internalHeight, setInternalHeight] = useState(height);
    
    useEffect(() => {
        setInternalKey(key);
        setInternalReadOnly(readOnly);
        setInternalContent(content);
        setInternalHeight(height);
      }, [key, content, readOnly]);

    return (
        <>
        {!internalReadOnly ? (
         <BundledEditor
            name={internalKey}
            onInit={(evt: any, editor: any) => {
                if (setEditorRef) {
                    setEditorRef(editor);
                }
            }}
            initialValue={internalContent}
            init={{
                promotion: false,
                branding: false,
                resize: true,
                height: `${internalHeight}`,
                menubar: false,
                plugins: [
                'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                'searchreplace', 'table', 'wordcount', 'preview', 'code'
                ],
                toolbar: 'undo redo | preview | blocks | ' +
                'fontfamily fontsize bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-weight:300; font-size:12px }'
            }}
            ></BundledEditor>
        ) : (
         <BundledEditor
            name={internalKey}
            initialValue={internalContent}
            disabled={true}
            init={{
                inline: true,
                promotion: false,
                branding: false,
                height: `${internalHeight}`,
                resize: false,
                menubar: false,
                readonly: true,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-weight:300; font-size:12px }'
            }}
            ></BundledEditor>           
        )}
        </>
    );
};