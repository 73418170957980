import React, { useEffect, useState } from "react";
import { getRequest } from "utils/axios";

import { Modal, Divider, Stack, Button, Loader, useToaster } from 'rsuite';
import MessageIcon from '@rsuite/icons/Message';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import SolutionFeedbackModal from "components/feedback/SolutionFeedbackModal";
import TaskMessaging from 'components/communication/TaskMessaging';
import TaskDetails from "components/tasks/TaskDetails";


interface Props {
  open: boolean;
  taskId: string;
  close: () => void;
}

export default function CanceledTaskModal({ open, taskId, close }: Props) {
  const toaster = useToaster();
  const [task, setTask] = useState<any>(null);
  const [taskMessagingModal, setTaskMessagingModalOpen] = useState<boolean>(false);
  const [solutionFeedbackModalOpen, setSolutionFeedbackModalOpen] = useState<boolean>(false);


  useEffect(() => {
    if (open && taskId) {
      getRequest(`/tasks/${taskId}/`).then((res) => {
        setTask(res.data);
      }).catch((error) => {
        toaster.push(<RequestErrorMessage error={error} toaster={toaster} />)
      })
    }

  }, [open, taskId])

  const handleClose = () => {
    close();
    setTask(null);
  }

  function handleSolutionFeedbackClose() {
    setSolutionFeedbackModalOpen(false);
    handleClose();
  }  

  const handleFeedback = () => {
    setSolutionFeedbackModalOpen(true);
  };    

  return (<>
    <TaskMessaging
        open={taskMessagingModal}
        task={task}
        close={() => setTaskMessagingModalOpen(false)}
        role="tasker"
      />

    {task?.solutions && (
      <SolutionFeedbackModal open={solutionFeedbackModalOpen} solution={task.solutions[0]} close={handleSolutionFeedbackClose} />
    )}

    <Modal size="lg" open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Task ({task?.current_status})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {task ? 
          <TaskDetails task={task} showDetails={true} showSolutionDetails={false} showNetAmount={false}/>
        : 
        <Loader size="md" content="Fetching task details..." />}
      </Modal.Body>
      <Modal.Footer>
        <Divider />
        <Stack justifyContent="space-between" spacing={10}>
          <Stack.Item>
            {task?.can_post_message && (
              <MessageIcon onClick={() => setTaskMessagingModalOpen(true)} />
            )}
          </Stack.Item>
          <Stack.Item>
            {task?.can_provide_feedback && (
              <Button onClick={handleFeedback} color="green" appearance="primary">Provide Feedback</Button>
            )}
          </Stack.Item>
        </Stack>
      </Modal.Footer>
    </Modal>
  </>);
}
