import { useEffect } from 'react';
import { Modal } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

interface Props {
    open: boolean;
    close: () => void;
}

const AnnouncementModal = ({ open, close }: Props) => {
    useEffect(() => {
        sendGAEvent("Announcement", "View", "Announcement Page");
    }, []);

    const handleClose = () => {
        close();
    };

    return (
        <Modal 
            size="lg" 
            open={open} 
            onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><div style={{textAlign: "center", margin: "5px", fontSize: "20px", fontWeight: "800", color: "#4eab0d"}}>Welcome to Zipply!</div></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '800px', overflowY: 'auto', fontSize: "13px" }}>
                <p>We are thrilled to announce the launch of Zipply, our Minimum Viable Product (MVP)! Zipply is designed to connect taskers with solvers in a fun and convenient way, enabling you to post and complete small tasks effortlessly. Our goal is to create a thriving community based on fair pricing and opportunity.</p>
                <p style={{color: "#3d8a0a", fontWeight: "800"}}>We’re seeking validation for Zipply, ensuring it’s a valuable resource for our users. As we gather feedback and insights from our early adopters, our goal is to shape a platform that genuinely meets your needs. By fostering a vibrant community where people can find solutions quickly and solvers can showcase their skills, we aim to create an experience that serves both sides seamlessly.</p>

                <h4>What is Zipply?</h4>

                <p>Zipply is where creativity meets convenience. It’s a platform that allows you to define tasks and set rewards for solvers to complete them. Whether you need help with a quick fix or want to showcase your skills as a solver, Zipply is here to make it happen!</p>

                <h4>Key Features of Zipply</h4>

                <ul>
                    <li><strong>Task Search:</strong> Easily find tasks that match your skills or interests.</li>
                    <li><strong>Email Notifications:</strong> Stay informed with email alerts for critical events</li>
                    <li><strong>Follower Concept:</strong> Follow your favorite taskers to receive notifications when they create new tasks, keeping you engaged with the community.</li>
                    <li><strong>User Stats:</strong> View user statistics via handles to help you make informed decisions on choosing taskers as a solver or vice versa.</li>
                    <li><strong>Badges:</strong> Earn and display badges for achievements, enhancing your profile and showcasing your contributions.</li>
                    <li><strong>In-Task Communication:</strong> Engage directly within tasks by:
                        Asking questions as a solver
                        Posting messages to solvers as a tasker</li>
                    <li><strong>Anonymity Options:</strong> Choose to remain anonymous through a username and display name option, allowing for privacy while participating in the community.</li>
                    <li><strong>User Referral:</strong> Invite friends to join Zipply and earn rewards for each successful referral.</li>
                </ul>

                <h4>What to Expect in This Initial Version</h4>

                <p>As an MVP, the first version of Zipply comes with some limitations:</p>
                <ul>
                    <li><strong>Limited Payment Integration:</strong> Currently, we support payments through Stripe, with basic payout options available today to streamline transactions.</li>
                    <li><strong>Simplified Search and Discovery:</strong> While the current search functionality allows for easy access to tasks, we’re excited to enhance your browsing experience with future updates.</li>
                    <li><strong>Basic Tasker Following:</strong> Although taskers can be followed and email notifications received, follower management and search are limited right now.</li>
                </ul>

                <h4>Your Feedback Matters</h4>

                <p>We have a backlog of exciting features planned to build a robust tasker-solver community. As we grow, we want your input to shape the direction of Zipply. Please share your thoughts, experiences, and suggestions with us!</p>

                <p>Thank you for joining us on this journey. Together, we can create a platform that empowers both taskers and solvers.</p>

                <strong>Let’s get started!</strong>
            </Modal.Body>
        </Modal>
    );
};

export default AnnouncementModal;
