import React from 'react';
import { Modal, Button } from 'rsuite';

type Props = {
    open: boolean
    handleClose: () => void
    handleConfirm: () => void
    title: string
    children?: React.ReactNode;
  };

const ConfirmationDialog = ({ open, handleClose, handleConfirm, title, children }: Props) => {
  return (
    <Modal backdrop="static" open={open} onClose={handleClose} size="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} appearance="primary">
          Confirm
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
