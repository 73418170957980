import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "types";
import { postRequest } from "utils/axios";
import axios from 'axios';

// Maybe a good resource to improve the code below? Especially for cleaner logouts.
// https://dev.to/mihaiandrei97/jwt-authentication-using-axios-interceptors-55be

type State = {
  token: string | null;
  refreshToken: string | null;
  user: User | null;
};

const initialState: State = { token: null, refreshToken: null, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(state: State, action: PayloadAction<{ token: string; refreshToken: string }>) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    setUser(state: State, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    setLogout(state: State) {
      state.user = null;
      state.refreshToken = null;
      state.token = null;
      postRequest('/auth/logout/');
    },
  },
});

export default authSlice;
