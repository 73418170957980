import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { User } from 'types';

const GoogleAnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
        }
    }, [location]);
    return null
}

export default GoogleAnalyticsTracker;


type GAEventrops = {
    category: string
    action: string
    label: string
}

export const GAEvent = ({ category, action, label }: GAEventrops) => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            ReactGA.event({
                category: category,
                action: action,
                label: label
                });
        }
    }, [location]);
    return null
}

 export const sendGAEvent = (category: any, action: any, label: any) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        ReactGA.event({
            category,
            action,
            label,
        });
    }
}

export const sendGAUserSignIn = (user: User, account: string) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        const userProperties = {
            username: user.username,
            email: user.email,
            name: user.full_name
        };
        ReactGA.set({ user_id: user.id, business_id: user.business_id, ...userProperties });
        ReactGA.event({
            category: "user",
            action: "sign_in",
            label: "User signed in with " + account
            });
    }
}

export const sendGAUserSignUp = (user: User) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        const userProperties = {
            username: user.username,
            email: user.email,
            name: user.full_name
        };
        ReactGA.set({ user_id: user.id, business_id: user.business_id, ...userProperties });
        ReactGA.event({
            category: "user",
            action: "sign_up",
            label: "User signed up with account"
            });
    }
}