import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import AdminAccountList from "pages/protected/admin/accounts/AdminAccountList"

import { Panel, Breadcrumb, Stack } from 'rsuite';

const AdminAccounts = () => {
  const navigate = useNavigate();
  const [accountCounts, setAccountCounts] = useState({ filtered: 0, total: 0});

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
  })

  return (<>
    <Panel className='header-panel' shaded
      header={<>
        <Stack direction='row' justifyContent="space-between">
          <Stack direction='row' justifyContent="flex-start">
            <Stack.Item>
              <h3 className="title">Accounts</h3>
            </Stack.Item>
            <Stack.Item style={{paddingLeft: '10px'}}>
              <span>You see {accountCounts.filtered} of {accountCounts.total} total accounts</span>
            </Stack.Item>
            </Stack>
        </Stack>

        <Breadcrumb>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Accounts</Breadcrumb.Item>
        </Breadcrumb>

      </>}>
    </Panel>
    <Panel className='body-panel' shaded style={{height: windowHeight - 200}}>
      <AdminAccountList accountCounts={accountCounts} setAccountCounts={setAccountCounts}/>
    </Panel>
</>)};

export default AdminAccounts;