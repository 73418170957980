import React, { useRef, useEffect, useState } from "react";
import { Modal, Button, Form, SelectPicker, useToaster, Schema } from 'rsuite';
import countryList from 'react-select-country-list';

import { patchFileRequest } from "utils/axios";
import { useDebounce } from 'use-debounce';
import useSWR from 'swr'
import { fetcher } from "utils/axios";

import RequestErrorMessage from 'components/toasts/RequestErrorMessage';

interface Props {
    profile: any
    open: boolean
    handleClose: () => void
    startTour: () => void
}

const countries = countryList().getData();
const excludedCountries = ['IR', 'KP', 'SY', 'RU', 'CU', 'VE', 'MM', 'SD', 'BY'];
const filteredCountries = countries.filter(
  (country: any) => !excludedCountries.includes(country.value)
);

function UserHandleInputModal({ profile, open, handleClose, startTour }: Props) {
    const toaster = useToaster();
    const formRef: any = useRef();
  
    const [formValue, setFormValue] = useState({
        username: '',
        displayName: '',
        countryIso: '',
    });

    const [delayedUsername, setDelayedUsername] = useState('');
    const [debouncedUsername] = useDebounce(delayedUsername, 500);
    const { data: usernameValidation } = useSWR<any>(`/users/0/validations/?username=${debouncedUsername}`, fetcher, {
        onSuccess: (data) => {
          formRef.current.checkForField('username')
        }
    })

    const [delayedDisplayName, setDelayedDisplayName] = useState('');
    const [debouncedDisplayName] = useDebounce(delayedDisplayName, 500);
    const { data: displayNameValidation } = useSWR<any>(`/users/0/validations/?display_name=${debouncedDisplayName}`, fetcher, {
        onSuccess: (data) => {
          formRef.current.checkForField('displayName')
        }
    })

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        username: StringType().isRequired("This field is required")
        .rangeLength(3, 30, 'Username must be between 3 and 30 characters')
        .addRule((value, data) => {
          return /^[a-zA-Z0-9_]+$/.test(value);
        }, 'Can only contain alphanumeric and underscore')
        .addRule((value, data) => {
          return usernameValidation && !usernameValidation.already_exists;
        }, 'Username already exists')
        .addRule((value, data) => {
          return usernameValidation && !usernameValidation.violates_policy;
        }, 'Username violates policy'),
        displayName: StringType().isRequired("This field is required")
        .rangeLength(3, 30, 'Display name must be between 3 and 30 characters')
        .addRule((value, data) => {
          return /^[a-zA-Z0-9\s.,!?]+$/.test(value);
        }, 'Can only contain alphanumeric, spaces, and punctuation')
        .addRule((value, data) => {
          return displayNameValidation && !displayNameValidation.violates_policy;
        }, 'Display name violates policy'),
        countryIso: StringType().isRequired("This field is required"),
    });

    const handleChange = (record: any) => {
        setFormValue({
            ...formValue,
            username: record.username,
            displayName: record.displayName,
            countryIso: record.countryIso,
        });
    };

    const handleSubmit = () => {
        if (formRef.current.check()) {
            const profileUpdateRequest = {
                username: formValue.username,
                display_name: formValue.displayName,
                country_iso: formValue.countryIso,
            };
            const patchPromise = patchFileRequest(`/users/${profile.id}/`, profileUpdateRequest);
            patchPromise
                .then((response) => {
                    handleClose();
                    startTour()
                })
                .catch((error) => {
                    toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
                });
        }
    };

    return (
        <Modal size="xs" open={open}>
            <Modal.Header>
                <Modal.Title>
                    <strong>Almost there!</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize: "14px"}}>We just need a few more details to set things up for you. Let’s get your username, display name, and country so we can make your experience even better!</p>
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    formValue={formValue}
                    model={model}
                    fluid
                >
                    <Form.Group controlId="username">
                        <Form.ControlLabel>Username</Form.ControlLabel>
                        <Form.Control name="username" value={delayedUsername} onChange={setDelayedUsername}/>
                        <Form.HelpText>Choose a username that will be displayed to other users and act as your handle. This cannot be changed later.</Form.HelpText>
                    </Form.Group>
                    <Form.Group controlId="displayName">
                        <Form.ControlLabel>Display Name</Form.ControlLabel>
                        <Form.Control name="displayName" value={delayedDisplayName} onChange={setDelayedDisplayName}/>
                        <Form.HelpText>Choose a display name that will be shown to other users. This can be changed later.</Form.HelpText>
                    </Form.Group>
                    <Form.Group controlId="country">                        
                        <Form.ControlLabel>Country</Form.ControlLabel>
                        <Form.Control 
                            name="countryIso"
                            accepter={SelectPicker}
                            style={{ 
                                width: '100%', 
                                border: '1px solid #ccc',  // Add border here
                                borderRadius: '4px'        // Optional: for rounded corners
                            }}
                            placeholder="Select your country of residence"
                            data={filteredCountries}
                            value={formValue.countryIso}
                            onChange={(value: any) => setFormValue({ ...formValue, countryIso: value })}
                            block
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSubmit} appearance="primary">
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UserHandleInputModal;
