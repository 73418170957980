import { useNavigate } from 'react-router';

import { Panel, Breadcrumb, Stack } from 'rsuite';

import SupportContent from './SupportContent'

const Support = () => {
  const navigate = useNavigate()

  return (<>
   <Panel>
      <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between'>
        <Stack.Item>
          <Breadcrumb style={{ fontSize: "20px" }}>
            <Breadcrumb.Item href="#" onClick={() => { navigate('/') }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Support</Breadcrumb.Item>
          </Breadcrumb>
        </Stack.Item>
      </Stack>
      <SupportContent />
    </Panel>
</>)};

export default Support;