import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { getRequest, postRequest } from "utils/axios";
import { useNavigate, Link } from "react-router-dom";

import { Modal, Button, Stack, Grid, Row, Col } from 'rsuite';

import ShowPasswordIcon from '@rsuite/icons/legacy/Eye';
import HidePasswordIcon from '@rsuite/icons/legacy/EyeSlash';

import 'styles/styles.css';

import { sendGAUserSignIn } from 'components/tracker/GoogleAnalyticsTracker';

import logo from "assets/images/zipply-logo-text-200x100.png"
import splash from "assets/images/zipply-splash.png"

interface Props {
  open: boolean
  handleClose: () => void
}

export default function SignInModal({ open, handleClose }: Props) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };    

  const googleAuthorizationUrl = process.env.REACT_APP_API_URL + '/auth/social/google/login/';

  const handleLogin = (username: any, password: any) => {
    postRequest('/auth/login/', { username, password, type: 'login' })
      .then((res) => {
        sessionStorage.removeItem('subscription_options_shown');
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setUser(res.data.user));
        const configurationPromise = getRequest('/system/configurations/ui/');
        configurationPromise.then((response) => {
          dispatch(configurationSlice.actions.setConfiguration(response.data));
        });
        sendGAUserSignIn(res.data, "account");
        navigate("/", {
          state: {
            userId: res.data.id,
          },
        });
      })
      .catch((err) => {
        setMessage("Wrong combination of username and password");
      });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      handleLogin(values.username, values.password);
    },
    validationSchema: Yup.object({
      username: Yup.string().trim().required("Username is required"),
      password: Yup.string().trim().required("Password is required"),
    }),
  });

  return (
      <Modal size="lg"
        open={open}
        onClose={handleClose}>
          <Grid fluid>
            <Row>
              <Col xsHidden={true} smHidden={true} mdHidden={true} lg={16} xl={16} xxl={16}>
                <div className="center-container" style={{paddingLeft: "20px"}}>
                <img src={splash} loading="lazy" style={{ width: "490px", height: "490px", borderRadius: "20px", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <div className="center-container">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="signin-container">
                      <a href="https://zipply.io">
                        <img src={logo} loading="lazy" style={{ marginBottom: "20px" }}/>
                      </a>
                      <input
                        name="username" 
                        type="text" 
                        id="username" 
                        placeholder="Email" 
                        autoComplete="username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username} />

                      <div className="password-container">
                        <input
                          name="password" 
                          type={showPassword ? 'text' : 'password'}  
                          id="password" 
                          placeholder="Password" 
                          autoComplete="current-password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password} />
                        <span className="password-toggle-icon"
                            onClick={togglePasswordVisibility} 
                        >
                            {showPassword ? 
                                <ShowPasswordIcon /> 
                              : 
                                <HidePasswordIcon /> 
                            }
                        </span>
                      </div>

                      <div className="d-grid">
                        <Button type="submit">Sign In</Button>
                      </div>

                      <div style={{ margin: "1em", color: "red" }}>{message}</div>
                      {formik.touched.username && formik.errors.username ? <div style={{ margin: "1em", color: "red" }}>{formik.errors.username}</div> : null}
                      {formik.touched.password && formik.errors.password ? <div style={{ margin: "1em", color: "red" }}>{formik.errors.password}</div> : null}

                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                        Don't have an account?&nbsp;<strong><Link to="/signup">Sign&nbsp;Up</Link></strong>
                      </div>
                      <div style={{ fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                        <strong><Link to="/resetpassword">Forgot&nbsp;Password?</Link></strong>
                      </div>
                    </div>
                  </form>

                  {process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "TRUE" &&
                    <div style={{ marginTop: "1em", textAlign: "center"}}>
                      <a href={googleAuthorizationUrl} className="google-sign-in-button-image" style={{ boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1"}}/>
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Stack spacing={80} justifyContent="center">
              <Button href='https://zipply.io/terms-of-service' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Terms of Service</Button>
              <Button href='https://zipply.io/privacy-policy' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Privacy Policy</Button>
            </Stack>
          </Grid>
      </Modal>
  );
}
