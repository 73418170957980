import React from 'react';
import { Uploader } from 'rsuite';

type FileUploaderProps = {
  description?: string; // Optional description
  height?: string | number; // Accept height as a string or number
  width?: string | number; // Accept height as a string or number
  action?: string; // URL for file upload, this is required
  [key: string]: any; // Allow other props to be passed in
};

const FileUploader = React.forwardRef<HTMLInputElement, FileUploaderProps>(({ height = 200, width = null, description = "Click or drag files to this area to upload", action = '', ...props }, ref) => (
  <Uploader {...props} ref={ref} action={action}>
    <div style={{ padding: "20px", height: `${height}px`, width: `${width}px`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <span>{description}</span>
    </div>
  </Uploader>
));

export default FileUploader;
